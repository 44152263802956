import { ManageSearch } from "@mui/icons-material";
import { Box, TextField, InputAdornment } from "@mui/material";
import { DateFormatter } from "../../utils/DateFormatter";
import { Control, UseFormGetValues, useWatch } from "react-hook-form";
import InputFormDatePicker from "../../components/form-components/InputFormDatePicker";
import { UseMutateFunction } from "@tanstack/react-query";
import { AvailableSubstratesResponse, Query, SoldSubstratesResponse } from "../../types";
import { Dispatch, SetStateAction } from "react";

type DateFilterProps = {
  filterText: string;
  setFilterText: Dispatch<SetStateAction<string>>;
  control: Control<Query, unknown>;
  getValues: UseFormGetValues<Query>;
  filterMutation: UseMutateFunction<
    AvailableSubstratesResponse[] | SoldSubstratesResponse[],
    Error,
    | {
        from: string;
        to: string;
      }
    | undefined,
    unknown
  >;
};

const DateFilter = ({ filterText, setFilterText, control, getValues, filterMutation }: DateFilterProps) => {
  useWatch({ name: "from", control });

  const submitQuery = (values: Query) => {
    const requestData = {
      from: DateFormatter(values.from),
      to: DateFormatter(values.to),
    };

    filterMutation(requestData);
  };

  const handleChange = () => {
    const { from, to } = getValues();
    if (from && to) {
      submitQuery({ from, to });
    }
  };

  return (
    <Box sx={{ display: "flex", gap: 3, mr: 4, alignItems: "center" }}>
      <InputFormDatePicker name="from" control={control} label="From" required={true} onChange={handleChange} unsetMargin />
      <InputFormDatePicker
        name="to"
        control={control}
        label="To"
        required={true}
        disabledTextField={getValues("from") ? false : true}
        onChange={handleChange}
        unsetMargin
      />
      <TextField
        label="Filter"
        onChange={(e) => setFilterText(e.target.value)}
        value={filterText}
        variant="outlined"
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ManageSearch />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default DateFilter;
