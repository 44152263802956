import { Container, Box, Typography, Divider, useTheme, Grid } from "@mui/material";
import { useState } from "react";
import { useFilteredSoldSubstrates, useFetchSoldSubstrates } from "../../../hooks/outgoingPos";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ShowError from "../../../components/ShowError";
import SubstratesTable from "../../../components/tables/SubstratesTable";
import { useForm, useWatch } from "react-hook-form";
import { createFilter } from "../../../utils/FilterData";
import { Query } from "../../../types";
import DateFilter from "../../components/DateFilter";

const defaultValues = {
  from: "",
  to: "",
};

const SoldSubstrates = () => {
  const theme = useTheme();
  const [filterText, setFilterText] = useState<string>("");
  const { data: soldSubstrates, isLoading, isError } = useFetchSoldSubstrates();
  const { mutate: filterSoldSubstrates } = useFilteredSoldSubstrates();
  const { control, getValues } = useForm<Query>({ defaultValues });
  useWatch({ name: "from", control });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Sold Substrates
        </Typography>
        <DateFilter
          filterText={filterText}
          setFilterText={setFilterText}
          control={control}
          getValues={getValues}
          filterMutation={filterSoldSubstrates}
        />
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {soldSubstrates && <SubstratesTable substrates={soldSubstrates.filter(createFilter(filterText))} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SoldSubstrates;
