import { DataGrid, GridColDef, GridFilterModel, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";
import { AvailableSubstratesResponse, SoldSubstratesResponse } from "../../types/index";
import { Box, Typography, useTheme } from "@mui/material";
import { NumberFormatter, FRONTEND_NUMBER_FORMAT } from "../../utils/NumberFormatter";
import { CheckCircle } from "@mui/icons-material";
import { DateFormatter, FRONTEND_SHORT_MONTH_FORMAT } from "../../utils/DateFormatter";
import { defaultTableStyle } from "../themes/tableStyle";

type SubstratesTableProps = {
  substrates: SoldSubstratesResponse[] | AvailableSubstratesResponse[];
};

const isSoldSubstrates = (substrates: SoldSubstratesResponse[] | AvailableSubstratesResponse[]): substrates is SoldSubstratesResponse[] => {
  return substrates.some((x) => Object.hasOwn(x, "soldKWh"));
};

const SubstratesTable = ({ substrates }: SubstratesTableProps) => {
  const theme = useTheme();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [{ id: 1, field: "selfDeclaration", operator: "is", value: "true" }],
  });

  const handleCo2Score = (params: GridRenderCellParams) => {
    let caption = "g CO2-eq/MJ";
    let color = theme.palette.error.light;
    let value = params.value.toFixed(1);

    if (params.value < 0) color = theme.palette.primary.main;
    if (params.value == 0) color = theme.palette.warning.light;

    if (params.row.selfDeclaration == 0) {
      caption = "";
      value = NaN;
      color = theme.palette.error.dark;
    }

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="caption" color={color} sx={{ p: 0.8 }}>
          <strong>{isNaN(value) ? "N/A" : NumberFormatter(value, FRONTEND_NUMBER_FORMAT, 1)}</strong>
        </Typography>
        <Typography variant="caption">{caption}</Typography>
      </Box>
    );
  };

  const substratesColum: GridColDef<SoldSubstratesResponse | AvailableSubstratesResponse>[] = [
    {
      field: "bioMassName",
      headerName: "Substrate",
      minWidth: 150,
      flex: 0.4,
      editable: false,
      renderCell: (params) => (
        <Typography variant="body1">
          <strong>{params.value}</strong>
        </Typography>
      ),
    },
    {
      field: "bioMassCategory",
      headerName: "Category",
      width: 110,
      editable: false,
      renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
    },
    {
      field: "posNumber",
      headerName: "PoS Number",
      minWidth: 250,
      flex: 0.4,
      editable: false,
      renderCell: (params) => (
        <Typography variant="caption" textOverflow="ellipsis">
          {params.value}
        </Typography>
      ),
    },
    {
      field: "plantName",
      headerName: "Plant",
      minWidth: 200,
      flex: 0.5,
      editable: false,
      renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
    },
    {
      field: "producedAt",
      headerName: "Produced",
      type: "string",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Box>
          <Typography variant="caption">{DateFormatter(params.value, FRONTEND_SHORT_MONTH_FORMAT)}</Typography>
        </Box>
      ),
    },
    {
      field: "issueDate",
      headerName: "Issue Date",
      type: "string",
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Box>
          <Typography variant="caption">{DateFormatter(params.value, FRONTEND_SHORT_MONTH_FORMAT)}</Typography>
        </Box>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      width: 80,
      editable: false,
      renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
    },
    {
      field: "selfDeclaration",
      headerName: "RED",
      type: "boolean",
      width: 100,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Box>{params.value ? <CheckCircle color="success" fontSize="small" /> : "-"}</Box>,
    },
    {
      field: "co2Score",
      headerName: "CI-Score",
      type: "number",
      width: 140,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: handleCo2Score,
    },
    {
      field: "subsidy",
      headerName: "Subsidy",
      type: "boolean",
      width: 100,
      editable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => <Typography variant="caption">{params.value ? "Yes" : "No"}</Typography>,
    },
    {
      field: isSoldSubstrates(substrates) ? "soldKWh" : "quantityKwh",
      headerName: "MWh",
      type: "number",
      width: 125,
      editable: false,
      align: "right",
      headerAlign: "right",
      valueGetter: (params) => params.value / 1000,
      renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
    },
    {
      field: "contractNumber",
      headerName: "Contract number",
      type: "number",
      flex: 0.4,
      minWidth: 175,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
    {
      field: "customerName",
      headerName: "Customer",
      type: "string",
      flex: 0.5,
      minWidth: 150,
      editable: false,
      align: "left",
      headerAlign: "left",
      renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
    },
  ];

  const commentField: GridColDef<SoldSubstratesResponse | AvailableSubstratesResponse> = {
    field: "comment",
    headerName: "Comment",
    type: "string",
    flex: 1,
    minWidth: 200,
    editable: false,
    align: "left",
    headerAlign: "left",
    renderCell: (params) => <Typography variant="caption">{params.value ? params.value : "-"}</Typography>,
  };

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      <DataGrid
        slots={{
          toolbar: GridToolbar,
        }}
        rows={substrates.map((data, index) => ({ ...data, id: `${data.posDataId}-${index}` }))}
        onFilterModelChange={(newFilterModel: GridFilterModel) => setFilterModel(newFilterModel)}
        filterModel={filterModel}
        disableColumnFilter={false}
        columns={isSoldSubstrates(substrates) ? substratesColum.concat(commentField) : substratesColum}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        hideFooterSelectedRowCount
        isRowSelectable={() => false}
        sx={defaultTableStyle}
      />
    </Box>
  );
};

export default SubstratesTable;
