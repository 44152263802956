import { useState } from "react";
import { Box, IconButton, Tooltip, Typography, Divider, useTheme, TextField, InputAdornment } from "@mui/material";
import { AddCircle, ManageSearch } from "@mui/icons-material";
import { useFetchBioMasses } from "../../../hooks/biomass";
import BioMassTable from "../../../components/tables/BioMassTable";
import CreateBioMass from "./CreateBioMass";
import { createFilter } from "../../../utils/FilterData";

const BioMassCenter = () => {
  const [showCreate, setShowCreate] = useState(false);
  const theme = useTheme();
  const { data } = useFetchBioMasses(true);
  const [filterText, setFilterText] = useState<string>("");

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Biomasses
        </Typography>
        <TextField
          label="Filter"
          onChange={(e) => setFilterText(e.target.value)}
          value={filterText}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ManageSearch />
              </InputAdornment>
            ),
          }}
        />
        <Box>
          <Tooltip title="Create new biomethane potential">
            <IconButton aria-label="create" color="primary" onClick={() => setShowCreate(!showCreate)}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ display: "flex" }}>
        <Box sx={{ minWidth: "50%" }}>
          {data ? <BioMassTable data={data.filter(createFilter(filterText))} /> : <Typography>No data is available.</Typography>}
        </Box>
        <Box sx={{ ml: 3, mt: 0.4 }}>{showCreate ? <CreateBioMass showCreate={(value: boolean) => setShowCreate(value)} /> : ""}</Box>
      </Box>
    </>
  );
};

export default BioMassCenter;
