import { Container, Box, Typography, Divider, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ShowError from "../../../components/ShowError";
import { createFilter } from "../../../utils/FilterData";
import SubstratesTable from "../../../components/tables/SubstratesTable";
import { useFetchAvailableSubstrates, useFilteredAvailableSubstrates } from "../../../hooks/incomingPos";
import DateFilter from "../../components/DateFilter";
import { Query } from "../../../types";

const defaultValues = {
  from: "",
  to: "",
};

const AvailableSubstrates = () => {
  const theme = useTheme();
  const [filterText, setFilterText] = useState<string>("");
  const { data: substrates, isLoading, isError } = useFetchAvailableSubstrates();
  const { mutate: filterAvailableSubstrates } = useFilteredAvailableSubstrates();
  const { control, getValues } = useForm<Query>({ defaultValues });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Available Substrates
        </Typography>
        <DateFilter
          filterText={filterText}
          setFilterText={setFilterText}
          control={control}
          getValues={getValues}
          filterMutation={filterAvailableSubstrates}
        />
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {substrates && <SubstratesTable substrates={substrates.filter(createFilter(filterText))} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default AvailableSubstrates;
