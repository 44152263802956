import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";

const InputFormDatePicker = ({
  name,
  control,
  required,
  label,
  errorText,
  formatViews,
  format,
  disabledTextField,
  onChange,
  unsetMargin,
  fullWidth,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          inputFormat={format ?? "MM/dd/yyyy"}
          label={label}
          value={field.value}
          onChange={(event) => {
            field.onChange(event);
            onChange && onChange(event);
          }}
          disabled={disabledTextField}
          views={formatViews ? formatViews : ["year", "day"]}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error ? errorText : null}
              disabled={disabledTextField}
              fullWidth={fullWidth}
              size="small"
              sx={{
                /*   ".MuiInputBase-input": { padding: 1.5 }, */
                m: unsetMargin ? "unset" : 2,
              }}
            />
          )}
        />
      )}
    />
  );
};

export default InputFormDatePicker;
