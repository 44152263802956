import { useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UpdateCustomer from "../../pages/customer/components/UpdateCustomer";
import { defaultTableStyle } from "../themes/tableStyle";
import { CustomerResponse } from "../../types/index";

type Props = {
  data: CustomerResponse[];
};

const CustomerTable = ({ data }: Props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState<CustomerResponse>();

  const customerToUpdate = (selectedCustomer: CustomerResponse) => {
    setSelectedRow(selectedCustomer);
    setShowEdit(true);
  };

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      {showEdit && selectedRow && <UpdateCustomer selectedCustomer={selectedRow} showEdit={(value: boolean) => setShowEdit(value)} />}
      <DataGrid
        rows={data}
        slots={{
          toolbar: GridToolbar,
        }}
        columns={[
          {
            field: "name",
            headerName: "Company name",
            minWidth: 120,
            maxWidth: 140,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "contactPerson",
            headerName: "Contact Person",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "street",
            headerName: "Street",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "city",
            headerName: "City",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "postalCode",
            headerName: "Postal Code",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "phoneNumber",
            headerName: "Phone number",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "country",
            headerName: "Country",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "certificateNumber",
            headerName: "Certificate number",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "actions",
            headerName: "",
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            align: "right",
            filterable: false,
            renderCell: (params) => {
              return (
                <Box>
                  <Tooltip title="Update customer" onClick={() => customerToUpdate(params.row)}>
                    <IconButton color="info" disabled={showEdit}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            },
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        isRowSelectable={() => false}
        hideFooterSelectedRowCount
        sx={defaultTableStyle}
      />
    </Box>
  );
};

export default CustomerTable;
