import { Avatar, Box, Typography, useTheme } from "@mui/material";

const ShowError = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: 5 }}>
      <Box sx={{ p: 2, my: 4, width: 400 }}>
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Avatar sx={{ width: 30, height: 30, bgcolor: theme.palette.error.main }}>!</Avatar>
          <Typography variant="body1" sx={{ p: 2 }}>
            Failed to load data.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ShowError;
