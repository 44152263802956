import { Box, Container, Divider, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AddCircle } from "@mui/icons-material";
import MassbalanceTable from "../../components/tables/MassBalanceTable";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import { useFetchMassbalances } from "../../hooks/massbalance";
import { useFetchIncomingPos } from "../../hooks/incomingPos";
import { useAuth } from "../../hooks/useAuth";
import { ADMIN_ROLE } from "../../services/base";

const Massbalance = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { user } = useAuth();
  const { data, isLoading, isError } = useFetchMassbalances();
  const { data: incomingPosList, isLoading: isPosListLoading } = useFetchIncomingPos();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Massbalances
        </Typography>
        <Box>
          <Tooltip title="Create new Mass balance">
            <IconButton aria-label="create" color="primary" onClick={() => navigate("/massbalances/new")}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ mt: 2 }}>
        {data && incomingPosList ? (
          <MassbalanceTable data={data} isAdmin={user?.role === ADMIN_ROLE} incomingPosData={{ incomingPosList, isPosListLoading }} />
        ) : (
          "No massbalances were found"
        )}
      </Box>
    </Container>
  );
};

export default Massbalance;
