import { useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { defaultTableStyle } from "../themes/tableStyle";
import { UserResponse } from "../../types/index";
import UpdateUser from "../../pages/users/components/UpdateUser";
import { useDeleteUser } from "../../hooks/users";

type Props = {
  data: UserResponse[];
};

const UserTable = ({ data }: Props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState<UserResponse>();
  const { mutate: deleteUser } = useDeleteUser();

  const userToUpdate = (selectedUser: UserResponse) => {
    setSelectedRow(selectedUser);
    setShowEdit(true);
  };

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      {showEdit && selectedRow && <UpdateUser selectedUser={selectedRow} showEdit={(value: boolean) => setShowEdit(value)} />}
      <DataGrid
        rows={data}
        slots={{
          toolbar: GridToolbar,
        }}
        columns={[
          {
            field: "name",
            headerName: "User",
            minWidth: 150,
            maxWidth: 220,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "email",
            headerName: "Email",
            minWidth: 150,
            maxWidth: 220,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "role",
            headerName: "Role",
            width: 100,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "plantName",
            headerName: "Plant",
            minWidth: 150,
            maxWidth: 200,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "customerName",
            headerName: "Customer Name",
            minWidth: 150,
            maxWidth: 200,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "actions",
            headerName: "",
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            align: "right",
            filterable: false,
            renderCell: (params) => {
              return (
                <Box>
                  <Tooltip title="Update user" onClick={() => userToUpdate(params.row)}>
                    <IconButton color="info" disabled={showEdit}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete user" onClick={() => deleteUser(params.row.id)}>
                    <IconButton disabled={showEdit}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            },
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        isRowSelectable={() => false}
        hideFooterSelectedRowCount
        sx={defaultTableStyle}
      />
    </Box>
  );
};

export default UserTable;
