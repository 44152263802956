import { useAuth } from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import AdminListPlantPotential from "./AdminListPlantPotential";

const AdminPlantPotentials = () => {

    const { id } = useParams() as { id: string };
    const { user } = useAuth();

    return (
      <Container>
        <AdminListPlantPotential plantId={id ?? user?.plantId} />
      </Container>
    );
};

export default AdminPlantPotentials;

