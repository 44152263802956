import { Box, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { defaultTableStyle } from "../themes/tableStyle";
import { DateFormatter, FRONTEND_MONTH_YEAR_FORMAT, FRONTEND_YEAR_MONTH_FORMAT } from "../../utils/DateFormatter";
import { EXPORT_NUMBER_FORMAT, FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { SubstrateMonthSummaryResponse } from "../../types";

type Props = {
  data: SubstrateMonthSummaryResponse[];
};

const MonthlySummaryTable = ({ data }: Props) => {
  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      <DataGrid
        getRowId={(row) => `${row.month}-${row.key}`}
        rows={data.filter((row) => row.substratesCount != 0).map((row, index) => ({ ...row, key: index }))}
        slots={{
          toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: { csvOptions: { fileName: "MonthlySummary" } },
        }}
        columns={[
          {
            field: "month",
            headerName: "Month",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "date",
            valueFormatter: (params) => DateFormatter(params.value, FRONTEND_YEAR_MONTH_FORMAT),
            renderCell: (params) => <Typography variant="caption">{DateFormatter(params.value, FRONTEND_MONTH_YEAR_FORMAT)}</Typography>,
          },
          {
            field: "expectedAverageGasPrice",
            headerName: "Expected Gas price",
            minWidth: 120,
            maxWidth: 150,
            flex: 1,
            type: "number",
            valueFormatter: (params) => NumberFormatter(params.value, EXPORT_NUMBER_FORMAT, 2),
            renderCell: (params) => (
              <Typography variant="caption">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 2)} EUR/m3</Typography>
            ),
          },
          {
            field: "expectedProduction",
            headerName: "Expected Production",
            minWidth: 120,
            maxWidth: 150,
            flex: 1,
            type: "number",
            valueFormatter: (params) => NumberFormatter(params.value, EXPORT_NUMBER_FORMAT, 2),
            renderCell: (params) => (
              <Typography variant="caption">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)} m3</Typography>
            ),
          },
          {
            field: "measuredAverageGasPrice",
            headerName: "Measured Gas Price",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "number",
            valueFormatter: (params) => NumberFormatter(params.value, EXPORT_NUMBER_FORMAT, 2),
            renderCell: (params) => (
              <Typography variant="caption">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 2)} EUR/m3</Typography>
            ),
          },
          {
            field: "measuredProduction",
            headerName: "Measured production",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "number",
            valueFormatter: (params) => NumberFormatter(params.value, EXPORT_NUMBER_FORMAT, 2),
            renderCell: (params) => (
              <Typography variant="caption">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)} m3</Typography>
            ),
          },
          {
            field: "totalGasPrice",
            headerName: "Total Gas price",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "number",
            valueFormatter: (params) => NumberFormatter(params.value, EXPORT_NUMBER_FORMAT, 2),
            renderCell: (params) => {
              return <Typography variant="caption">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)} EUR</Typography>;
            },
          },
          {
            field: "substratesCount",
            headerName: "Feedstocks Count",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "number",
            valueFormatter: (params) => NumberFormatter(params.value, EXPORT_NUMBER_FORMAT, 0),
            renderCell: (params) => <Typography variant="caption">{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        isRowSelectable={() => false}
        hideFooterSelectedRowCount
        sx={defaultTableStyle}
      />
    </Box>
  );
};

export default MonthlySummaryTable;
