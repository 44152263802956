import { Typography, Box } from "@mui/material";

const Footer = () => {
  return (
    <Box my={10} maxWidth={824}>
      <Typography variant="caption">
        The project have received funding from EUDP in Denmark, SWEA in Sweden and from the framework of the joint programming initiative
        ERA-Net Smart Energy Systems’ focus initiative Digital Transformation for the Energy Transition, with support from the European
        Union’s Horizon 2020 research and innovation programme under grant agreement No 883973.
      </Typography>
      <Box mt={4} display="flex" gap={3} justifyContent="center" alignItems="center">
        <img src="/assets/SmartEnergySystems_2018_rgb.png" alt="" style={{ objectFit: "cover", height: 90 }} />
        <img src="/assets/EUDP_Logo_Green_RGB.png" alt="" style={{ objectFit: "cover", height: 48 }} />
        <img src="/assets/energimyndigheten_logo.png" alt="" style={{ objectFit: "cover", height: 53 }} />
      </Box>
    </Box>
  );
};
export default Footer;
