import { useState } from "react";
import { Box, Divider, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ShowError from "../../../components/ShowError";
import BioMassPotentialTable from "../../../components/tables/BioMassPotentialTable";
import { useFetchPlantBioMassProducts } from "../../../hooks/biomassproduct";
import CreateBioMassProduct from "../../plant-owner/components/CreateBioMassProduct";

type ListPotentialProps = {
  plantId: string;
};

const ListPotential = ({ plantId }: ListPotentialProps) => {
  const [showCreate, setShowCreate] = useState(false);
  const theme = useTheme();
  const { data, isLoading, isError } = useFetchPlantBioMassProducts(plantId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Feedstocks
        </Typography>
        {!showCreate && (
          <Box>
            <Tooltip title="Create new feedstocks">
              <IconButton aria-label="create" color="primary" onClick={() => setShowCreate(true)}>
                <AddCircle />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      {showCreate && (
        <Box sx={{ m: 2, p: 2 }}>
          <CreateBioMassProduct plantId={plantId} showCreate={(value: boolean) => setShowCreate(value)} />
        </Box>
      )}
      {data && <BioMassPotentialTable data={data} />}
    </>
  );
};

export default ListPotential;
