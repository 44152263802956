import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, CircularProgress, Container, Divider, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import InputFormText from "../../components/form-components/InputFormText";
import InputFormDatePicker from "../../components/form-components/InputFormDatePicker";
import { InputFormRadio } from "../../components/form-components/InputFormRadio";
import { useAddPlant } from "../../hooks/plant";
import { DateFormatter } from "../../utils/DateFormatter";
import { InputFormSelect } from "../../components/form-components/InputFormSelect";
import { availableCountries } from "../../utils/CountryToIso";
import { PlantRequest } from "../../types";

interface CreatePlantForm {
  name: string;
  country: string;
  certificateNumber: string;
  closedDigestate: string;
  offGasCombustion: string;
  commissioningDate: Date;
  street: string;
  postalCode: string;
  city: string;
  contactPerson: string;
  phoneNumber: string;
}

const defaultValues: CreatePlantForm = {
  name: "",
  country: "",
  certificateNumber: "",
  closedDigestate: "false",
  offGasCombustion: "false",
  commissioningDate: new Date(),
  street: "",
  postalCode: "",
  city: "",
  contactPerson: "",
  phoneNumber: "",
};

const CreatePlant = () => {
  const methods = useForm<CreatePlantForm>({ defaultValues });
  const { handleSubmit, control } = methods;
  const navigate = useNavigate();
  const { mutate: addPlant, isPending, isError } = useAddPlant();

  const onSubmit = (values: CreatePlantForm) => {
    const requestData: PlantRequest = {
      ...values,
      commissioningDate: DateFormatter(values.commissioningDate),
      offGasCombustion: Boolean(values.offGasCombustion),
      closedDigestate: Boolean(values.closedDigestate),
    };

    addPlant(requestData, {
      onSuccess: () => {
        navigate("/plants");
      },
    });
  };

  const checkboxData = [
    {
      label: "No",
      value: "false",
    },
    {
      label: "Yes",
      value: "true",
    },
  ];

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" color="primary" sx={{ flexGrow: 1 }}>
          Create Plant
        </Typography>
      </Box>
      <Divider color="primary" sx={{ my: 2 }} />
      <Box sx={{ maxWidth: 550, display: "flex", justifyContent: "center" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ maxWidth: 500 }}>
              <Box sx={{ display: "flex" }}>
                <InputFormText name="name" control={control} label="Plant name" required errorText={"Plant name is required"} />
                <InputFormSelect
                  name="country"
                  control={control}
                  label="Country"
                  required
                  errorText={"Country is required"}
                  data={availableCountries}
                />
              </Box>

              <Box sx={{ display: "flex" }}>
                <InputFormText name="street" control={control} label="Street name" required errorText={"Street name is required"} />
                <InputFormText name="postalCode" control={control} label="Postal code" required errorText={"Postal code is required"} />
              </Box>
              <Box sx={{ width: "94%" }}>
                <InputFormText name="city" control={control} label="City" required errorText={"City is required"} />
              </Box>
              <Box sx={{ display: "flex" }}>
                <InputFormText
                  name="contactPerson"
                  control={control}
                  label="Contact person"
                  required
                  errorText={"Contact person is required"}
                />
                <InputFormText name="phoneNumber" control={control} label="Phone number" required errorText={"Phone number is required"} />
              </Box>
              <Box sx={{ width: "94%" }}>
                <InputFormText
                  name="certificateNumber"
                  control={control}
                  label="Certificate number"
                  required
                  errorText={"Certificate number is required"}
                />
              </Box>
              <Box sx={{ width: "94%" }}>
                <InputFormDatePicker
                  name="commissioningDate"
                  control={control}
                  label="Commissioning date"
                  format="dd/MM/yyyy"
                  formatViews={["day", "month", "year"]}
                  required
                  errorText={"Commissioning date is required"}
                  fullWidth
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
              <InputFormRadio name="offGasCombustion" control={control} label="Off Gas Combustion" data={checkboxData} />
              <InputFormRadio name="closedDigestate" control={control} label="Closed digestate" data={checkboxData} />
            </Box>
            <Divider sx={{ m: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "end" }}>
              {isPending ? (
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  <Button variant="text" color="warning" onClick={() => navigate("/plants")}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="success" type="submit">
                    Save
                  </Button>
                </>
              )}
            </Box>
            {isError ? (
              <Box>
                <Alert severity="error">
                  <Typography variant="body2">Something went wrong. Please try again later. </Typography>
                </Alert>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default CreatePlant;
