import { AddCircleOutline, CheckCircleOutline, Delete } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFetchMassbalances } from "../../hooks/massbalance";
import { useDeleteMonthlyProduction } from "../../hooks/plant";
import { DateFormatter, FRONTEND_SHORT_MONTH_FORMAT } from "../../utils/DateFormatter";
import { FRONTEND_NUMBER_FORMAT, FRONTEND_PERCENT_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { defaultTableStyle } from "../themes/tableStyle";
import { ProductionDataResponse } from "../../types";

type MonthlyReportTableProps = {
  data: ProductionDataResponse[];
  plantId: string;
};

const MonthlyReportTable = ({ data, plantId }: MonthlyReportTableProps) => {
  const navigate = useNavigate();
  const { data: massBalances } = useFetchMassbalances();
  const { mutate: deleteProduction } = useDeleteMonthlyProduction(plantId);

  const deleteProductionData = (id: string) =>
    deleteProduction(id, {
      onSuccess: () => {
        toast.success("Production data successfully removed!");
      },
      onError(error, variables, context) {
        console.info(error, variables, context);
      },
    });

  const getMassBalance = (row: ProductionDataResponse) => {
    if (!massBalances) return null;
    return massBalances.find((item: { productionDataId: string }) => row.id === item.productionDataId);
  };

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      <DataGrid
        rows={data}
        slots={{
          toolbar: GridToolbar,
        }}
        columns={[
          {
            field: "producedAt",
            headerName: "Produced",
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption"> {DateFormatter(params.value, FRONTEND_SHORT_MONTH_FORMAT)}</Typography>,
          },
          {
            field: "certificatesKWh",
            headerName: "Certificates",
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption"> {NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
          {
            field: "otherUsageKWh",
            headerName: "Other",
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption"> {NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
          {
            field: "torchKWh",
            headerName: "Flare",
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption"> {NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 0)}</Typography>,
          },
          {
            field: "methaneLoss",
            headerName: "Methane loss",
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => (
              <Typography variant="caption">{NumberFormatter(params.value, FRONTEND_PERCENT_FORMAT, 2)} %</Typography>
            ),
          },
          {
            field: "actions",
            headerName: "",
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            align: "right",
            filterable: false,
            renderCell: (params) => {
              const massbalance = getMassBalance(params.row);
              return (
                <>
                  {massbalance ? (
                    <IconButton aria-label="create" color="primary" onClick={() => navigate(`/massbalances/${massbalance.id}`)}>
                      <Tooltip title="Go to mass balance">
                        <CheckCircleOutline color="primary" />
                      </Tooltip>
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="create"
                      color="primary"
                      onClick={() =>
                        navigate(`/massbalances/new`, { state: { selectedDate: new Date(params.row.producedAt), plantSelected: plantId } })
                      }>
                      <Tooltip title="Create mass balance">
                        <AddCircleOutline />
                      </Tooltip>
                    </IconButton>
                  )}
                  <IconButton
                    disabled={!!getMassBalance(params.row)}
                    aria-label="delete"
                    color="primary"
                    onClick={() => deleteProductionData(params.row.id)}>
                    <Tooltip title="Delete production data">
                      <Delete />
                    </Tooltip>
                  </IconButton>
                </>
              );
            },
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        isRowSelectable={() => false}
        hideFooterSelectedRowCount
        sx={defaultTableStyle}
      />
    </Box>
  );
};

export default MonthlyReportTable;
