import { useState } from "react";
import { useForm } from "react-hook-form";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { CreateUserForm, RoleCategory, UserRequest } from "../../../types/index";
import { useAddUser } from "../../../hooks/users";
import UserForm from "./UserForm";

const defaultValues: CreateUserForm = {
  name: "",
  email: "",
  plantId: null,
  customerId: null,
  userType: 3,
};

type CreateUserProps = {
  showCreate: (value: boolean) => void;
};

const CreateUser = ({ showCreate }: CreateUserProps) => {
  const [loading, setLoading] = useState(false);
  const methods = useForm({ defaultValues: defaultValues, mode: "onSubmit" });
  const { handleSubmit, control, resetField, formState, clearErrors, getValues } = methods;
  const { mutate: addUser } = useAddUser();

  const onSubmit = async (submittedValue: CreateUserForm) => {
    const newUser: UserRequest = {
      name: submittedValue.name,
      email: submittedValue.email,
      plantId: submittedValue.plantId ? submittedValue.plantId : null,
      customerId: submittedValue.customerId ? submittedValue.customerId : null,
      role: submittedValue.userType ? RoleCategory.PlantOwner : RoleCategory.Customer,
    };

    setLoading(true);
    addUser(newUser, {
      onSuccess: () => {
        showCreate(false);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box sx={{ p: 2, my: 2 }}>
        <Typography variant="h6">New User</Typography>
        <UserForm control={control} resetField={resetField} errors={formState.errors} clearErrors={clearErrors} getValues={getValues} />
        <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
          <Button variant="text" color="secondary" onClick={() => showCreate(false)}>
            Cancel
          </Button>
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button variant="contained" color="success" type="submit">
              Save
            </Button>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default CreateUser;
