import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

export const InputFormCheckbox = ({ name, control, label }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={value} />} label={label} onChange={onChange} />
        </FormGroup>
      )}
    />
  );
};
