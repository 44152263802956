import { PropsWithChildren } from "react";
import { Navigate, Outlet } from "react-router-dom";

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath: string;
}

export const ProtectedRoute = ({ isAllowed, children, redirectPath = "/" }: PropsWithChildren<ProtectedRouteProps>) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
