import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

export const InputFormRadio = ({ name, control, label, data, horizontalDirection }: FormInputProps) => {
  const generateRadioOptions = () => {
    if (data) {
      return data.map((singleOption: { value: unknown; label: string }, index: number) => (
        <FormControlLabel key={index} value={singleOption.value} label={singleOption.label} control={<Radio />} />
      ));
    }
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioGroup value={value} onChange={onChange} row={horizontalDirection}>
            {generateRadioOptions()}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};
