import { CloudDownload, PictureAsPdf } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { DateFormatter, FRONTEND_DATE_FORMAT } from "../../utils/DateFormatter";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { defaultTableStyle } from "../themes/tableStyle";
import { onDownloadFile, onDownloadTransit } from "../../utils/DownloadFiles";
import { ImportedOutgoingPosResponse } from "../../types";

type ImportedOutgoingPosTableProps = {
  data: ImportedOutgoingPosResponse[];
};

const ImportedOutgoingPosTable = ({ data }: ImportedOutgoingPosTableProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      <DataGrid
        rows={data}
        slots={{
          toolbar: GridToolbar,
        }}
        columns={[
          {
            field: "uniqueNumber",
            headerName: "PoS Number",
            minWidth: 250,
            flex: 1,
            editable: false,
            renderCell: (params) => (
              <Typography variant="caption">
                <strong>{params.value}</strong>
              </Typography>
            ),
          },
          {
            field: "issueDate",
            headerName: "Issue Date",
            flex: 0.4,
            editable: false,
            renderCell: (params) => (
              <Typography variant="caption" color={theme.palette.warning.main} sx={{ mr: 1 }}>
                <strong>{DateFormatter(params.value, FRONTEND_DATE_FORMAT)} </strong>
              </Typography>
            ),
          },
          {
            field: "supplier",
            headerName: "From",
            flex: 0.3,
            editable: false,
            valueGetter: (params) => (params.value.name == "Biogem ApS" ? "DK" : "SE"),
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "recipient",
            headerName: "Reciever",
            flex: 1,
            editable: false,
            valueGetter: (params) => params.row.recipient.name,
          },
          {
            field: "type",
            headerName: "Type",
            flex: 0.5,
            editable: false,
            valueGetter: (params) => params.value,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "totalQuantityKwh",
            headerName: "Quantity(GCV)",
            flex: 0.5,
            editable: false,
            align: "left",
            renderCell: (params) => (
              <Typography variant="caption">{NumberFormatter(params.value / 1000, FRONTEND_NUMBER_FORMAT)} MWh</Typography>
            ),
          },
          {
            field: "co2Score",
            headerName: "CO2 eq",
            flex: 0.35,
            editable: false,
            align: "left",
            renderCell: (params) => (
              <Typography variant="caption">
                {params.row.type == "Non RED" ? "N/A" : NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 2)}
              </Typography>
            ),
          },
          {
            field: "co2Savings",
            headerName: "CO2 savings",
            flex: 0.45,
            editable: false,
            align: "left",
            renderCell: (params) => (
              <Typography variant="caption">
                {params.row.type == "Non RED" ? "N/A" : NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 1) + "%"}
              </Typography>
            ),
          },
          {
            field: "actions",
            type: "actions",
            headerName: "",
            width: 100,
            cellClassName: "actions",
            getActions: (params) => {
              const displayNormalArray = [
                <GridActionsCellItem
                  hidden={true}
                  key={0}
                  label="Download as Excel"
                  showInMenu
                  icon={<CloudDownload color="primary" />}
                  onClick={() =>
                    onDownloadFile({
                      format: "xlsx",
                      id: params.row.id,
                      fileName: params.row.uniqueNumber,
                      dataType: "importedoutgoingpos",
                    })
                  }
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
                <GridActionsCellItem
                  key={1}
                  label="Download as PDF"
                  showInMenu
                  icon={<PictureAsPdf color="primary" />}
                  onClick={() =>
                    onDownloadFile({
                      format: "pdf",
                      id: params.row.id,
                      fileName: params.row.uniqueNumber,
                      dataType: "importedoutgoingpos",
                    })
                  }
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
              ];

              const displayOptionOnTransitArray = [
                <GridActionsCellItem
                  hidden={true}
                  key={0}
                  label="Download as Excel"
                  showInMenu
                  icon={<CloudDownload color="primary" />}
                  onClick={() =>
                    onDownloadFile({
                      format: "xlsx",
                      id: params.row.id,
                      fileName: params.row.uniqueNumber,
                      dataType: "importedoutgoingpos",
                    })
                  }
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
                <GridActionsCellItem
                  key={1}
                  label="Download as PDF"
                  showInMenu
                  icon={<PictureAsPdf color="primary" />}
                  onClick={() =>
                    onDownloadFile({
                      format: "pdf",
                      id: params.row.id,
                      fileName: params.row.uniqueNumber,
                      dataType: "importedoutgoingpos",
                    })
                  }
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
                <GridActionsCellItem
                  key={2}
                  label=" Download Transit Excel"
                  showInMenu
                  icon={<CloudDownload color="primary" />}
                  onClick={() => onDownloadTransit({ format: "xlsx", id: params.row.id, fileName: params.row.uniqueNumber })}
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
                <GridActionsCellItem
                  key={3}
                  label="Download Transit PDF"
                  showInMenu
                  icon={<PictureAsPdf color="primary" />}
                  onClick={() => onDownloadTransit({ format: "pdf", id: params.row.id, fileName: params.row.uniqueNumber })}
                  nonce={undefined}
                  onResize={undefined}
                  onResizeCapture={undefined}
                />,
              ];

              return params && params.row.supplier.name != "Biogem ApS" ? displayOptionOnTransitArray : displayNormalArray;
            },
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        hideFooterSelectedRowCount
        isRowSelectable={() => false}
        sx={defaultTableStyle}
      />
    </Box>
  );
};

export default ImportedOutgoingPosTable;
