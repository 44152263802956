import { Box, Container, Divider, Typography, useTheme } from "@mui/material";
import CreateMassbalance from "./components/CreateMassbalance";

const MassbalanceCenter = () => {
  const theme = useTheme();

  return (
    <Container sx={{ mb: 5 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
            Create Massbalance
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ m: 2, bgcolor: theme.palette.primary.main }} />
      <CreateMassbalance />
    </Container>
  );
};

export default MassbalanceCenter;
