import { useNavigate } from "react-router-dom";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { DataGrid, GridRowParams } from "@mui/x-data-grid";
import { Lock } from "@mui/icons-material";
import { DateFormatter, FRONTEND_READABLE_DAY, BACKEND_DATE_FORMAT } from "../../../utils/DateFormatter";
import { useFetchSubstratDays, useUnLockSubstrateDaylist } from "../../../hooks/substrates";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { NumberFormatter, FRONTEND_NUMBER_FORMAT } from "../../../utils/NumberFormatter";
import { SubstrateMonthListResponse } from "../../../types";

const getAllDates = (year: number, month: number) => {
  const date = new Date(year, month - 1, 1);
  const dates = [];

  while (date.getMonth() == month - 1) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

type MonthlyProductionTableProps = {
  monthData: SubstrateMonthListResponse;
};

const MonthlyProductionTable = ({ monthData }: MonthlyProductionTableProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { mutate: unLockSubstrateDayMutation } = useUnLockSubstrateDaylist();

  const date = new Date(monthData.producedAt);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  const { data: daysData = [], isLoading: isDaysLoading } = useFetchSubstratDays(monthData.id);

  const dates = getAllDates(year, month);
  const tableData = dates
    .map((date: Date) => {
      return {
        id: DateFormatter(date, BACKEND_DATE_FORMAT),
        day: DateFormatter(date, BACKEND_DATE_FORMAT),
        comment: "",
        isLocked: monthData.isLocked,
        expectedProduction: 0,
        substratesCount: 0,
      };
    })
    .filter((el) => daysData.findIndex((dataEl) => dataEl.day == el.day) == -1)
    .concat(daysData);
  tableData.sort((a, b) => (a.day < b.day ? -1 : 1));

  const unlockDay = (dayListId: string) => {
    unLockSubstrateDayMutation(dayListId);
  };

  const handleRowClick = (params: GridRowParams) => {
    const formattedDate = params.row.day.replace(/-/g, "/");
    navigate(`/daily-production/${formattedDate}`);
  };

  if (isDaysLoading) return <LoadingSpinner />;

  return (
    <>
      <Box sx={{ height: "100vh", maxWidth: "100%" }}>
        <DataGrid
          rows={tableData}
          getRowClassName={(params) => {
            if (params.row.isLocked) {
              return `RowLocked`;
            }
            return "";
          }}
          columns={[
            {
              field: "dayOfMonth",
              headerName: "Day Of Month",
              flex: 0.5,
              editable: false,
              renderCell: (params) => (
                <Typography variant="body1">
                  <strong>{DateFormatter(params.row.day, FRONTEND_READABLE_DAY)}</strong>
                </Typography>
              ),
            },
            {
              field: "substratesCount",
              headerName: "Feedstocks",
              flex: 0.5,
              editable: false,
              renderCell: (params) => (
                <Typography variant="caption">
                  <strong>{params.value || "-"}</strong>
                </Typography>
              ),
            },
            {
              field: "measuredProduction",
              headerName: "Measured Production",
              flex: 1,
              editable: false,
              renderCell: (params) => (
                <Typography variant="caption">
                  {params.value ? `${NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 1)} m3` : "-"}
                </Typography>
              ),
            },
            {
              field: "expectedProduction",
              headerName: "Expected Production",
              flex: 1,
              editable: false,
              renderCell: (params) => (
                <Typography variant="caption">
                  {params.value ? `${NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 1)} m3` : "-"}
                </Typography>
              ),
            },
            {
              field: "gasPrice",
              headerName: "Average price per m3",
              flex: 1,
              editable: false,
              renderCell: (params) => (
                <Typography variant="caption">
                  {params.value ? `${NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 3)} EUR/m3` : "-"}
                </Typography>
              ),
            },
            {
              field: "status",
              headerName: "Status",
              type: "boolean",
              align: "right",
              flex: 0.5,
              editable: false,
              renderCell: (params) => {
                return (
                  <Box sx={{ mr: 3 }}>
                    {params.row.isLocked ? (
                      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                        <Tooltip title="Unlock day">
                          <IconButton
                            aria-label="create"
                            color="primary"
                            onClick={() => unlockDay(params.row.id)}
                            disabled={monthData.isLocked}>
                            <Lock />
                          </IconButton>
                        </Tooltip>
                        <Typography variant="body1">Locked</Typography>
                      </Box>
                    ) : (
                      <Typography variant="body1">Open</Typography>
                    )}
                  </Box>
                );
              },
            },
          ]}
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 31 } } }}
          density="compact"
          onRowClick={(params) => handleRowClick(params)}
          hideFooter
          sx={{
            border: 0,
            [`& .MuiDataGrid-cell `]: {
              borderBottom: "none",
              "&:focus-within": {
                outline: "none",
              },
            },
            [`& .MuiDataGrid-row `]: {
              my: 0.3,
              borderRadius: 1,
              [`&:hover`]: {
                background: theme.palette.mode === "dark" ? "#333333 !important" : "#f5f5f5 !important",
              },
            },
            [`.RowLocked`]: {
              [`&:hover`]: {
                background: theme.palette.mode === "dark" ? "#2b7c3d !important" : "#5fb878 !important",
              },
            },
            [`& .MuiDataGrid-columnSeparator `]: {
              display: "none",
            },
            "& .RowLocked": {
              background:
                theme.palette.mode === "dark" ? `${theme.palette.primary.dark} !important` : `${theme.palette.primary.light} !important`,
            },
          }}
        />
      </Box>
    </>
  );
};

export default MonthlyProductionTable;
