import { Alert, Box, Button, CircularProgress, Divider, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import InputFormText from "../../../components/form-components/InputFormText";
import "react-day-picker/dist/style.css";
import { DayPicker } from "react-day-picker";
import { useCreateBulkSubstrate } from "../../../hooks/substrates";
import { BACKEND_DATE_FORMAT, DateFormatter } from "../../../utils/DateFormatter";
import { BACKEND_NUMBER_FORMAT, NumberFormatter } from "../../../utils/NumberFormatter";
import { BioMassProductResponse, BioMassResponse, PlantResponse, SupplierResponse } from "../../../types";


type BulkSubstrateForm = {
  id: string;
  bioMass: BioMassResponse;
  potential: number;
  pricePerTonnage: number;
  plant: PlantResponse;
  supplier: SupplierResponse;
  label: string;
  starred: boolean;
  weight: number;
  selfDeclaration: boolean;
};

type AddDailyBioMassPotentialProps = {
  data: BioMassProductResponse;
  showAdd: (value: boolean) => void;
};

const AddDailyBioMassPotential = ({ data, showAdd }: AddDailyBioMassPotentialProps) => {
  const theme = useTheme();
  const methods = useForm<BulkSubstrateForm>();
  const { handleSubmit, control, reset } = methods;
  const bulkCreate = useCreateBulkSubstrate();

  useEffect(() => {
    const defaultValues = {
      id: data.id,
      bioMass: data.bioMass,
      potential: data.potential,
      pricePerTonnage: data.pricePerTonnage,
      plant: data.plant,
      supplier: data.supplier,
      label: data.label,
      starred: data.starred,
    };
    reset(defaultValues);
  }, [data, reset]);

  const onSubmit = (values: BulkSubstrateForm) => {
    const daysPayload: string[] = [];
    days?.forEach((d) => daysPayload.push(DateFormatter(d, BACKEND_DATE_FORMAT)));

    const tonnage = NumberFormatter(values.weight, BACKEND_NUMBER_FORMAT) as number;

    const payload = {
      biomassId: values.bioMass.id,
      supplierId: values.supplier.id,
      tonnage: tonnage,
      price: values.pricePerTonnage,
      potential: values.potential,
      transportType: "",
      plantId: values.plant.id,
      days: daysPayload,
    };

    bulkCreate.mutate(payload, {
      onSuccess: () => {
        toast.success("Biomethane potential successfully updated!");
        showAdd(false);
      },
    });
  };

  const initialDays: Date[] = [];
  const [days, setDays] = useState<Date[] | undefined>(initialDays);
  const [key, setKey] = useState<string>("");

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      setKey(e.key);
    }

    function handleKeyUp() {
      setKey("");
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const pressDay = (dayList: Date[] | undefined) => {
    if (dayList == undefined) return;

    if (key === "Shift") {
      // SHIFT
      let day1 = dayList.pop() ?? new Date();
      let day2 = dayList.pop() ?? new Date();

      if (day1 > day2) [day1, day2] = [day2, day1];

      const dayNumbers = dayList.map((d: Date) => d.valueOf());
      const day = day1;

      while (day < day2) {
        if (!dayNumbers.includes(day.valueOf())) dayList.push(new Date(day));
        day.setDate(day.getDate() + 1);
      }
      dayList.push(new Date(day));
    }
    setDays(dayList);
  };

  const footer = days && days.length > 0 ? <p>You have selected {days.length} day(s).</p> : <p>Please pick one or more days.</p>;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body2">Add BioMassProduct</Typography>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ display: "flex", gap: 5, alignItems: "center", justifyContent: "center" }}>
        <TextField disabled id="biomass" label="Biomassproduct" size="small" value={data && data.label} />
        <Box sx={{ maxWidth: 500, display: "flex" }}>
          <Box sx={{ display: "flex", maxWidth: 150 }}>
            <InputFormText name="weight" type="number" control={control} label="Weight" required={true} errorText={"Weight required"} />
          </Box>
        </Box>
      </Box>

      <DayPicker
        mode="multiple"
        min={1}
        weekStartsOn={1}
        selected={days}
        onSelect={pressDay}
        footer={footer}
        ISOWeek
        showWeekNumber
        showOutsideDays
      />
      <Divider sx={{ my: 1, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button variant="text" color="secondary" onClick={() => showAdd(false)}>
          Cancel
        </Button>
        {bulkCreate.isPending ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Button variant="contained" color="success" type="submit">
            Save
          </Button>
        )}
      </Box>
      {bulkCreate.isError ? (
        <Box mt={2}>
          <Alert severity="error">
            <Typography variant="body2">Something went wrong. Please try again later. </Typography>
          </Alert>
        </Box>
      ) : (
        ""
      )}
    </form>
  );
};

export default AddDailyBioMassPotential;
