import axios from "../services/axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../services/base";
import { DataResponse, BathtubResponse, BathTubSummaryResponse, BathTubSummaryRequest } from "../types";

export const useFetchBathtub = () => {
  return useQuery({
    queryKey: ["bathtub"],
    queryFn: async () => {
      const { data } = await axios.get<DataResponse<BathtubResponse[]>>(`${BASE_URL}/bathtub`);
      return data.data;
    },
  });
};

export const useFetchBathtubSummary = () => {
  return useMutation({
    mutationFn: async (values: BathTubSummaryRequest) => {
      const { data } = await axios.get<DataResponse<BathTubSummaryResponse>>(
        `${BASE_URL}/bathtub/summary?from=${encodeURIComponent(values.from)}&to=${encodeURIComponent(values.to)}&redOnly=${values.redOnly}`,
      );
      return data.data;
    },
  });
};
