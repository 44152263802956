import { Divider, Box, useTheme } from "@mui/material";
import InputFormText from "../../../components/form-components/InputFormText";
import { Control } from "react-hook-form";
import { CustomerRequest } from "../../../types/index";
import { InputFormSelect } from "../../../components/form-components/InputFormSelect";
import { availableCountries } from "../../../utils/CountryToIso";

type CustomerForm = {
  control: Control<CustomerRequest, unknown>;
};

const CustomerForm = ({ control }: CustomerForm) => {
  const theme = useTheme();
  return (
    <>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ display: "flex" }}>
        <InputFormText name="name" control={control} label="Company name" required errorText={"Company name is required"} />
        <InputFormText name="contactPerson" control={control} label="Contact person" required errorText={"Contact Person is required"} />
        <InputFormText name="phoneNumber" control={control} label="Phone number" required errorText={"Phone number is required"} />
      </Box>
      <Box sx={{ display: "flex" }}>
        <InputFormText name="street" control={control} label="Street name" required errorText={"Street name is required"} />
        <InputFormText name="postalCode" control={control} label="Postal code" required errorText={"Postal code is required"} />
        <InputFormText name="city" control={control} label="City" required errorText={"City is required"} />
      </Box>
      <Box sx={{ display: "flex" }}>
        <InputFormSelect
          name="country"
          control={control}
          label="Country"
          required
          errorText={"Country is required"}
          data={availableCountries}
        />
        <InputFormText name="certificateNumber" control={control} label="Certificate number" />
      </Box>
      <Box sx={{ display: "flex" }}>
        <InputFormText name="powerBiUrl" control={control} label="PowerBi URL" />
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
    </>
  );
};

export default CustomerForm;
