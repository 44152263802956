export interface UserResponse {
  id: string;
  email: string;
  name: string;
  role: Role;
  plantId?: string;
  plantName?: string;
  customerId?: string;
  customerName?: string;
}

export interface UserRequest {
  name: string;
  email: string;
  role: number;
  plantId?: string | null;
  customerId?: string | null;
}

export type CreateUserForm = Omit<UserRequest, "role"> & {
  userType: number;
};

export const RoleCategory = {
  Admin: 1,
  PlantOwner: 2,
  Customer: 3,
};

export type Role = keyof typeof RoleCategory;
