import { useEffect, useState, KeyboardEvent } from "react";
import {
  DataGrid,
  GridFilterModel,
  GridRenderCellParams,
  GridRowEntry,
  GridToolbar,
} from "@mui/x-data-grid";
import { Grid, Box, Typography, Button, useTheme, TextField } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";
import SummaryModal from "../../pages/storage/components/SummaryModal";
import { DateFormatter, FRONTEND_SHORT_MONTH_FORMAT } from "../../utils/DateFormatter";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { BathTubCartItemResponse, BathtubResponse, Calculation } from "../../types";

type Props = {
  data: BathtubResponse[];
};

const CertificateStorageTable = ({ data }: Props) => {
  const [totaltAmount, setTotalAmount] = useState<Calculation | null>(null);
  const [selectedRows, setSelectedRows] = useState<BathTubCartItemResponse[]>([]);
  const [redCertMode] = useState(true);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [{ id: 1, field: "selfDeclaration", operator: "is", value: "true" }],
  });

  useEffect(() => {
    setFilterModel({
      items: [{ id: 1, field: "selfDeclaration", operator: "is", value: redCertMode ? "true" : "false" }],
    });
    setTotalAmount(null);
    setSelectedRows([]);
  }, [redCertMode]);

  useEffect(() => {
    // Calculate new total amounts
    if (selectedRows.length === 0) setTotalAmount(null);
    else {
      const totalMWh = selectedRows.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
      const totalCo2 = selectedRows.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount * currentValue.sku.gramCo2EqPerMegaJoule,
        0,
      );

      const co2Score = totalCo2 / totalMWh;
      const totalAmount: Calculation = {
        higherValue: totalMWh,
        co2scoreValue: Number(co2Score.toFixed(2)),
        co2savings: Number(((1 - co2Score / 94) * 100).toFixed(0)),
        lowerValue: Number(((11.057 / 12.157) * totalMWh).toFixed(0)),
      };

      setTotalAmount(totalAmount);
    }
  }, [selectedRows]);

  const getInputValueMWh = (row: GridRowEntry) => {
    const selected = selectedRows.find((r) => r.sku.id === row.id);
    if (selected) {
      return selected.amount;
    }
    return 0;
  };

  const handleCo2Score = (params: GridRenderCellParams) => {
    let caption = "g CO2-eq/MJ";
    let color = theme.palette.error.light;
    let value = params.value.toFixed(1);

    if (params.value < 0) color = theme.palette.primary.main;
    if (params.value == 0) color = theme.palette.warning.light;

    if (params.row.selfDeclaration == 0) {
      caption = "";
      value = NaN;
      color = theme.palette.error.dark;
    }

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="caption" color={color} sx={{ p: 0.8 }}>
          <strong>{isNaN(value) ? "N/A" : NumberFormatter(value, FRONTEND_NUMBER_FORMAT, 1)}</strong>
        </Typography>
        <Typography variant="caption">{caption}</Typography>
      </Box>
    );
  };

  const editRowAmount = (params: GridRenderCellParams, newAmount: number) => {
    const selectedRowsWithoutCurrent = selectedRows.filter((r) => r.sku.id !== params.id);
    if (newAmount === 0) {
      setSelectedRows(selectedRowsWithoutCurrent);
    } else {
      const updatedRow: BathTubCartItemResponse = {
        sku: params.row,
        amount: newAmount,
      };
      setSelectedRows([...selectedRowsWithoutCurrent, updatedRow]);
    }
  };

  const renderMWhColumn = (params: GridRenderCellParams) => {
    return (
      <Box width="100%" sx={{ display: "flex", alignItems: "center" }}>
        <TextField
          value={getInputValueMWh(params.row)}
          variant="standard"
          size="small"
          type="number"
          sx={{
            backgroundColor: "rgba(38, 153, 70, 0.05)",
            borderRadius: 1,
            fontSize: "5px",
            paddingLeft: 1,
            paddingRight: 1,
            marginRight: 1,
            width: 64,
          }}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter" || e.key === "Escape") {
              // TODO Unfocus
            }
          }}
          onChange={(e) => {
            const min = 0;
            const max = Math.trunc(params.value);
            let value = parseInt(e.target.value, 10);

            if (value > max) value = max;
            if (value < min) value = min;

            editRowAmount(params, value);
          }}
        />
        <Typography
          variant="caption"
          className="hover"
          onClick={() => editRowAmount(params, getInputValueMWh(params.row) == 0 ? params.value : 0)}>
          <strong> of {Math.trunc(params.value)}</strong>
        </Typography>
      </Box>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Box sx={{ height: 750, maxWidth: "100%" }}>
            <DataGrid
              slots={{
                toolbar: GridToolbar,
              }}
              rows={data}
              onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
              filterModel={filterModel}
              disableColumnFilter={false}
              columns={[
                {
                  field: "bioMassName",
                  headerName: "Substrate",
                  width: 150,
                  editable: false,
                  renderCell: (params) => (
                    <Typography variant="body1">
                      <strong>{params.value}</strong>
                    </Typography>
                  ),
                },
                {
                  field: "bioMassCategory",
                  headerName: "Category",
                  width: 110,
                  editable: false,
                  renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
                },
                {
                  field: "posNumber",
                  headerName: "PoS Number",
                  width: 250,
                  editable: false,
                  renderCell: (params) => (
                    <Typography variant="caption" textOverflow="ellipsis">
                      {params.value}
                    </Typography>
                  ),
                },
                {
                  field: "plantName",
                  headerName: "Plant",
                  width: 100,
                  editable: false,
                  renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
                },
                {
                  field: "producedAt",
                  headerName: "Produced",
                  type: "string",
                  width: 100,
                  editable: false,
                  renderCell: (params) => (
                    <Box>
                      <Typography variant="caption">{DateFormatter(params.value, FRONTEND_SHORT_MONTH_FORMAT)}</Typography>
                    </Box>
                  ),
                },
                {
                  field: "expiresAt",
                  headerName: "Expires",
                  type: "string",
                  width: 100,
                  editable: false,
                  renderCell: (params) => (
                    <Box>
                      <Typography variant="caption">{DateFormatter(params.value, FRONTEND_SHORT_MONTH_FORMAT)}</Typography>
                    </Box>
                  ),
                },
                {
                  field: "country",
                  headerName: "Country",
                  width: 80,
                  editable: false,
                  renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
                },
                {
                  field: "selfDeclaration",
                  headerName: "RED",
                  type: "boolean",
                  width: 100,
                  editable: false,
                  align: "left",
                  renderCell: (params) => <Box>{params.value ? <CheckCircle color="success" fontSize="small" /> : "-"}</Box>,
                },
                {
                  field: "gramCo2EqPerMegaJoule",
                  headerName: "CI-Score",
                  type: "number",
                  width: 140,
                  editable: false,
                  align: "left",
                  headerAlign: "left",
                  renderCell: handleCo2Score,
                },
                {
                  field: "subsidy",
                  headerName: "Subsidy",
                  type: "boolean",
                  width: 100,
                  editable: false,
                  align: "left",
                  headerAlign: "left",
                  renderCell: (params) => <Typography variant="caption">{params.value ? "Yes" : "No"}</Typography>,
                },
                {
                  field: "availableKWh",
                  headerName: "MWh",
                  type: "number",
                  width: 136,
                  editable: false,
                  align: "left",
                  headerAlign: "left",
                  valueGetter: (params) => params.value / 1000,
                  renderCell: renderMWhColumn,
                },
              ]}
              initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
              density="compact"
              hideFooterSelectedRowCount
              isRowSelectable={() => false}
              getRowClassName={(params) => {
                const rowIsAdded = selectedRows.find((item) => params.id === item.sku.id);
                return rowIsAdded?.sku.id === params.row.id ? `RowWithUserAmountAdded` : "";
              }}
              sx={{
                [`& .MuiDataGrid-cell `]: {
                  borderBottom: "none",
                  "&:focus-within": {
                    outline: "none",
                  },
                },
                "& .Mui-selected": {
                  background: theme.palette.mode === "dark" ? "#3d6b3f !important" : "none",
                },
                [`& .MuiDataGrid-row `]: {
                  background: theme.palette.mode === "dark" ? "#333333" : "#fff",
                  my: 0.3,
                  borderRadius: 1,
                  maxWidth: "100%",
                  "&:hover": {
                    background: theme.palette.mode === "dark" ? "#333333" : "#fff",
                  },
                },
                [`&.MuiDataGrid-root `]: {
                  border: "none",
                },
                [`& .MuiDataGrid-columnSeparator `]: {
                  display: "none",
                },
                "& .RowWithUserAmountAdded": {
                  background: "rgba(38, 153, 70, 0.1)",
                  my: 0.3,
                  borderRadius: 1,
                  maxWidth: "100%",
                  "&:hover": {
                    background: "rgba(38, 153, 70, 0.1)",
                  },
                },
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box sx={{ display: "flex", justifyContent: "end", mt: 2.5, mb: 2 }}>
            <Button onClick={() => setOpen(true)} disabled={selectedRows && selectedRows.length === 0} variant="contained" color="success">
              Preview
            </Button>
          </Box>
          <Box sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <Box>
              <Typography variant="body2" color={theme.palette.primary.main}>
                {totaltAmount?.higherValue ?? "-"}
              </Typography>
              <Typography variant="caption">Energy content(GCV), MWh</Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                color={
                  redCertMode
                    ? totaltAmount?.co2scoreValue != null && totaltAmount?.co2scoreValue <= 0
                      ? theme.palette.primary.main
                      : theme.palette.error.main
                    : theme.palette.error.main
                }>
                {redCertMode ? NumberFormatter(totaltAmount?.co2scoreValue, FRONTEND_NUMBER_FORMAT, 2) ?? "-" : "N/A"}
              </Typography>
              <Typography variant="caption">Emissions, CO2-eq g/MJ</Typography>
            </Box>
            <Box>
              <Typography
                variant="body2"
                color={
                  redCertMode
                    ? totaltAmount?.co2scoreValue != null && totaltAmount?.co2scoreValue <= 0
                      ? theme.palette.primary.main
                      : theme.palette.error.main
                    : theme.palette.error.main
                }>
                {redCertMode ? totaltAmount?.co2savings ?? "-" : "N/A"}
              </Typography>
              <Typography variant="caption">CO2 savings, %</Typography>
            </Box>
            <Box>
              <Typography variant="body2" color={theme.palette.warning.main}>
                {redCertMode ? totaltAmount?.lowerValue ?? "-" : "N/A"}
              </Typography>
              <Typography variant="caption">Energy content (LCV), MWh</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <SummaryModal
        onOpen={open}
        onClose={(newSelectedRows: BathTubCartItemResponse[]) => {
          setSelectedRows(newSelectedRows);
          setOpen(false);
        }}
        data={selectedRows}
        totalAmount={totaltAmount}
      />
    </>
  );
};

export default CertificateStorageTable;
