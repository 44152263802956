export const FRONTEND_NUMBER_FORMAT = "dk";
export const BACKEND_NUMBER_FORMAT = "backend";
export const FRONTEND_PERCENT_FORMAT = "percent";
export const EXPORT_NUMBER_FORMAT = "export";

type NumberFormat = 
  typeof FRONTEND_NUMBER_FORMAT |
  typeof BACKEND_NUMBER_FORMAT |
  typeof FRONTEND_PERCENT_FORMAT |
  typeof EXPORT_NUMBER_FORMAT;

export const NumberFormatter = (number: string | number | undefined | null, numberFormat?: NumberFormat, decimals = 0) => {
  if (number === undefined || number === null) return null;
  if (typeof number == "number") number = number.toString();

  switch (numberFormat?.toLocaleLowerCase()) {
    case "dk":
      number = number.replace(",", ".");
      number = parseFloat(number).toFixed(decimals);
      return new Intl.NumberFormat("da-DK", { maximumFractionDigits: decimals, minimumFractionDigits: decimals }).format(
        parseFloat(number),
      );

    case "percent":
      number = (parseFloat(number) * 100).toFixed(decimals);
      return new Intl.NumberFormat("da-DK", { maximumFractionDigits: decimals, minimumFractionDigits: decimals }).format(
        parseFloat(number),
      );

    case "backend":
      number = number.replace(",", ".");
      number = parseFloat(number).toString();
      return parseFloat(number);

    case "export":
      return new Intl.NumberFormat("da-DK", {
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
        useGrouping: false,
      }).format(parseFloat(number));

    default:
      number = parseFloat(number).toFixed(decimals);
      return new Intl.NumberFormat("da-DK", { maximumFractionDigits: decimals, minimumFractionDigits: decimals }).format(
        parseFloat(number),
      );
  }
};