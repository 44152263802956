import axios from "../services/axios";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { BASE_URL } from "../services/base";
import { BioMassProductRequest, BioMassProductResponse, UpdateBioMassProductRequest } from "../types";

export const useFetchPlantBioMassProducts = (plantId: string) => {
  return useQuery<BioMassProductResponse[]>({
    queryKey: ["biomassproducts"],
    queryFn: async () => {
      const { data } = await axios.get(`${BASE_URL}/biomassproducts/${plantId}`);
      return data.data;
    },
  });
};

export const useDeletePlantBioMassProducts = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (bioMassProductId: string) => {
      const { data } = await axios.delete(`${BASE_URL}/biomassproducts/${bioMassProductId}`);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["biomassproducts"] });
    },
  });
};

export const useUpdateBioMassProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (biomassProduct: UpdateBioMassProductRequest) => {
      const { id: biomassProductId, ...bioMassData } = biomassProduct;
      const { data } = await axios.put(`${BASE_URL}/biomassproducts/${biomassProductId}`, bioMassData);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["biomassproducts"] });
    },
  });
};

export const useCreateBioMassProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (bioMassData: BioMassProductRequest) => {
      const { data } = await axios.post(`${BASE_URL}/biomassproducts`, bioMassData);
      return data.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["biomassproducts"] });
    },
  });
};
