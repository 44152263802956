import { CheckCircle } from "@mui/icons-material";
import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { SubstrateListResponse } from "../../types/SubstrateListType";

type SubstrateListTableProps = {
  data: SubstrateListResponse[];
};

const SubstrateListTable = ({ data }: SubstrateListTableProps) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="body1" sx={{ mx: 2 }}>
        Substrate list
      </Typography>
      <Divider sx={{ mb: 2, mx: 2, bgcolor: theme.palette.primary.main }} />
      <Table size="small" aria-label="substrate list">
        <TableHead>
          <TableRow>
            <TableCell>Biomass</TableCell>
            <TableCell>Supplier</TableCell>
            <TableCell>Tonnage</TableCell>
            <TableCell>Self declaration</TableCell>
            <TableCell>Country</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.map((substrateRow) => (
              <TableRow key={substrateRow.id}>
                <TableCell component="th" scope="row">
                  {substrateRow.bioMassRaw}
                </TableCell>
                <TableCell>{substrateRow.supplierRaw}</TableCell>
                <TableCell>{NumberFormatter(substrateRow.tonnage, FRONTEND_NUMBER_FORMAT)}</TableCell>
                <TableCell>{substrateRow.selfDeclaration ? <CheckCircle color="success" fontSize="small" /> : ""}</TableCell>
                <TableCell>{substrateRow.country}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default SubstrateListTable;
