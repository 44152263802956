import { Theme } from "@mui/material";

export function defaultTableStyle(theme: Theme) {
  return {
    border: 0,
    [`& .MuiDataGrid-cell `]: {
      borderBottom: "none",
      "&:focus-within": {
        outline: "none",
      },
    },
    [`& .Mui-selected `]: {
      background: theme.palette.mode === "dark" ? "#3d6b3f !important" : "none",
    },
    [`& .MuiDataGrid-row `]: {
      background: theme.palette.mode === "dark" ? "#333333" : "none",
      my: 0.3,
      borderRadius: 1,
      maxWidth: "100%",
      [`&:hover`]: {
        background: theme.palette.mode === "dark" ? "#333333 !important" : "#f5f5f5 !important",
      },
    },
    [`& .MuiDataGrid-columnSeparator `]: {
      display: "none",
    },
  };
}
