import { AddCircle } from "@mui/icons-material";
import { Box, Container, Divider, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import IncomingPosTable from "../../components/tables/IncomingPosTable";
import { useFetchIncomingPos } from "../../hooks/incomingPos";
import ImportPos from "../components/ImportPos";
import { useAuth } from "../../hooks/useAuth";
import { ADMIN_ROLE } from "../../services/base";

const IncomingPos = () => {
  const { data, isLoading, isError } = useFetchIncomingPos();
  const theme = useTheme();
  const [showCreate, setShowCreate] = useState(false);
  const { user } = useAuth();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Produced & Bought PoS
        </Typography>
        {!showCreate && (
          <Tooltip title="Create new PoS">
            <IconButton aria-label="create" color="primary" onClick={() => setShowCreate(!showCreate)}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {showCreate && <ImportPos showCreate={(value: boolean) => setShowCreate(value)} />}
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ mt: 2 }}>{data ? <IncomingPosTable data={data} isAdmin={user?.role === ADMIN_ROLE} /> : "No data were found"}</Box>
    </Container>
  );
};

export default IncomingPos;
