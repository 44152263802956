import { Box, Button, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

const InputFormFile = ({ name, control, label, required, format, errorText }: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button variant="contained" color="primary" component="label">
            {label}
            <input
              hidden
              accept={format ? `${format}/*` : "*"}
              type="file"
              onChange={(e) => {
                if (e.target.files) {
                  onChange(e.target.files[0]);
                }
              }}
            />
          </Button>
          <Typography sx={{ ml: 3 }}>{value ? value.name : ""}</Typography>
          {required && error ? <Typography color="error">{errorText}</Typography> : ""}
        </Box>
      )}
    />
  );
};

export default InputFormFile;
