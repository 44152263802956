import { toast } from "react-toastify";
import { DateFormatter, FRONTEND_SHORT_MONTH_FORMAT } from "../../../utils/DateFormatter";
import { Tooltip, IconButton, useTheme, Box, Button, Typography, Divider } from "@mui/material";
import { Delete, LockOpen, CheckCircle, AddCircle, AddCircleOutline } from "@mui/icons-material";
import { useFetchSubstrateMonthLists, useDeleteSubstrateMonthList, useUnlockSubstrateMonth } from "../../../hooks/substrates";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import ImportSubstrateData from "./ImportSubstrateData";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ShowError from "../../../components/ShowError";
import ConfirmModal from "../../../components/ConfirmModal";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { SubstrateMonthListResponse } from "../../../types/SubstratesType";

type SubstrateListTableProps = {
  plantId: string;
  data: SubstrateMonthListResponse[];
};

const SubstrateListTable = ({ data, plantId }: SubstrateListTableProps) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [deleteRow, setDeleteRow] = useState<SubstrateMonthListResponse | null>(null);
  const { mutate: deleteSubstrateListMutation } = useDeleteSubstrateMonthList(plantId);
  const { mutate: unlockSubstrateListMutation } = useUnlockSubstrateMonth(plantId);

  if (data && data.length === 0) {
    return (
      <Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography>No data is available.</Typography>
        </Box>
      </Box>
    );
  }

  const onDeleteDialogClosed = (cancelled: boolean) => {
    if (!cancelled && deleteRow) {
      deleteSubstrateListMutation(deleteRow.id, {
        onSuccess: () => {
          toast.success("Substrate list deleted successfully");
        },
      });
    }

    setDeleteRow(null);
  };

  const onUnlockClicked = (id: string) => {
    unlockSubstrateListMutation(id, {
      onSuccess: () => {
        toast.success("Substrate list is unlocked");
      },
    });
  };

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      <DataGrid
        rows={data}
        slots={{
          toolbar: GridToolbar,
        }}
        columns={[
          {
            field: "producedAt",
            headerName: "Produced",
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "isPlanning",
            headerName: "Type",
            flex: 1,
            type: "boolean",
            editable: false,
            renderCell: (params) => {
              return <Typography variant="caption">{params.row.isPlanning ? "Planning" : "Imported"}</Typography>;
            },
          },
          {
            field: "substratesCount",
            headerName: "Count",
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "massBalanceId",
            headerName: "Mass balance",
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => {
              return (
                <Box>
                  {!!params.row.massBalanceId && (
                    <Tooltip title="Go to mass balance">
                      <Button size="small" onClick={() => navigate(`/massbalances/${params.row.massBalanceId}`)}>
                        <CheckCircle />
                      </Button>
                    </Tooltip>
                  )}
                  {!params.row.massBalanceId && (
                    <Tooltip title="Create mass balance">
                      <Button
                        size="small"
                        onClick={() =>
                          navigate(`/massbalances/new`, {
                            state: { selectedDate: new Date(params.row.producedAt), plantSelected: plantId },
                          })
                        }>
                        <AddCircleOutline />
                      </Button>
                    </Tooltip>
                  )}
                </Box>
              );
            },
          },
          {
            field: "actions",
            headerName: "",
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            align: "right",
            filterable: false,
            renderCell: (params) => {
              return (
                <Box>
                  {params.row.isPlanning && (
                    <IconButton
                      disabled={!!params.row.massBalanceId}
                      aria-label="Unlock planning"
                      color="primary"
                      onClick={() => onUnlockClicked(params.row.id)}
                      sx={{ p: 0, m: 0 }}>
                      <LockOpen />
                    </IconButton>
                  )}
                  <Tooltip title="Delete substrate list">
                    <IconButton
                      disabled={params.row.isPlanning || !!params.row.massBalanceId}
                      aria-label="Delete substrate list"
                      color="primary"
                      onClick={() => setDeleteRow(params.row)}
                      sx={{ p: 0, m: 0 }}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            },
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        isRowSelectable={() => false}
        hideFooterSelectedRowCount
        sx={{
          border: 0,
          [`& .MuiDataGrid-cell `]: {
            borderBottom: "none",
            "&:focus-within": {
              outline: "none",
            },
          },
          [`& .Mui-selected `]: {
            background: theme.palette.mode === "dark" ? "#3d6b3f !important" : "none",
          },
          [`& .MuiDataGrid-row `]: {
            background: theme.palette.mode === "dark" ? "#333333" : "none",
            my: 0.3,
            borderRadius: 1,
            maxWidth: "100%",
            [`&:hover`]: {
              background: theme.palette.mode === "dark" ? "#333333 !important" : "#f5f5f5 !important",
            },
          },
          [`& .MuiDataGrid-columnSeparator `]: {
            display: "none",
          },
        }}
      />
      {!!deleteRow && (
        <ConfirmModal
          open={true}
          title="Delete substrate list"
          actionButtonSuccess="Yes"
          actionButtonCancel="No"
          onClose={onDeleteDialogClosed.bind(this)}>
          <Box>
            Are you sure you want to delete substrate list for {DateFormatter(deleteRow.producedAt.toString(), FRONTEND_SHORT_MONTH_FORMAT)}
            ?
          </Box>
        </ConfirmModal>
      )}
    </Box>
  );
};

const ListSubstrateData = ({ plantId }: { plantId: string }) => {
  const location = useLocation();
  const [showCreate, setShowCreate] = useState(location?.state?.showCreate || false);
  const theme = useTheme();
  const { data, isLoading, isError } = useFetchSubstrateMonthLists(plantId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", minHeight: 45 }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Substrate lists
        </Typography>

        {!showCreate && (
          <Box>
            <Tooltip title="Import new substrate data">
              <IconButton aria-label="create" color="primary" onClick={() => setShowCreate(true)}>
                <AddCircle />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      {showCreate && <ImportSubstrateData plantId={plantId} showCreate={(value: boolean) => setShowCreate(value)} />}
      <Divider sx={{ my: 1, bgcolor: theme.palette.primary.main }} />

      {data && <SubstrateListTable data={data} plantId={plantId} />}
    </>
  );
};

export default ListSubstrateData;
