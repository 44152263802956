import { Alert, Box, Button, CircularProgress, Divider, TextField, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import InputFormText from "../../../components/form-components/InputFormText";
import { useUpdateBioMassProduct } from "../../../hooks/biomassproduct";
import { BioMassProductResponse, UpdateBioMassProductRequest } from "../../../types";

const defaultValues: UpdateBioMassProductRequest = {
  id: "",
  potential: 0,
  pricePerTonnage: 0,
  label: "",
  starred: false,
};

type Props = {
  data: BioMassProductResponse;
  showUpdate: (value: boolean) => void;
};

const EditBioMassPotential = ({ data, showUpdate }: Props) => {
  const theme = useTheme();
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, control, reset } = methods;
  const updatePotential = useUpdateBioMassProduct();

  useEffect(() => {
    const defaultValues = {
      id: data?.id,
      potential: data?.potential,
      pricePerTonnage: data?.pricePerTonnage,
      label: data?.label,
      starred: data?.starred,
    };
    reset(defaultValues);
  }, [data, reset]);

  const onSubmit = (values: UpdateBioMassProductRequest) => {
    updatePotential.mutate(values, {
      onSuccess: () => {
        toast.success("Biomethane potential successfully updated!");
        showUpdate(false);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="body2">Update biomethane potential</Typography>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ display: "flex", gap: 5, alignItems: "center", justifyContent: "center" }}>
        <TextField disabled id="biomass" label="Biomass" size="small" value={data && data.bioMass.name} />
        <Box>
          <InputFormText name="label" control={control} label="Label" required errorText={"Label is required"} />
        </Box>
        <Box>
          <InputFormText
            name="pricePerTonnage"
            control={control}
            label="Price per ton"
            required={true}
            errorText={"Price per tonnage is required"}
          />
        </Box>
        <Box>
          <InputFormText
            name="potential"
            control={control}
            label="Biomethane potential"
            required={true}
            errorText={"Biomethane potential is required"}
          />
        </Box>
      </Box>

      <Divider sx={{ my: 1, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Button variant="text" color="secondary" onClick={() => showUpdate(false)}>
          Cancel
        </Button>
        {updatePotential.isPending ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Button variant="contained" color="success" type="submit">
            Save
          </Button>
        )}
      </Box>
      {updatePotential.isError && (
        <Box mt={2}>
          <Alert severity="error">
            <Typography variant="body2">Something went wrong. Please try again later. </Typography>
          </Alert>
        </Box>
      )}
    </form>
  );
};

export default EditBioMassPotential;
