import { Box, Button, Typography, useTheme } from "@mui/material";
import { useFetchPlantBioMassProducts } from "../../../hooks/biomassproduct";
import { useCreateSubstrate } from "../../../hooks/substrates";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { SubstrateListDayResponse, SubstrateListRequest } from "../../../types/SubstrateListType";
import { BioMassProductResponse } from "../../../types";

type StarredBiomassesProps = {
  plantId: string;
  daylist: SubstrateListDayResponse;
};

const StarredBiomasses = ({ plantId, daylist }: StarredBiomassesProps) => {
  const { data, isLoading } = useFetchPlantBioMassProducts(plantId);
  const { mutate: createSubstrateMutation } = useCreateSubstrate(daylist.id);
  const starredFeedstock = data?.filter((bmp) => bmp.starred);
  const theme = useTheme();

  const addBiomassProduct = (input: BioMassProductResponse) => {
    const payload: SubstrateListRequest = {
      tonnage: 0,
      price: input.pricePerTonnage,
      biomassId: input.bioMass.id,
      supplierId: input.supplier.id,
      transportType: "null",
      potential: input.potential,
    };
    createSubstrateMutation(payload);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {starredFeedstock && starredFeedstock.length > 0 && (
        <Box sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }}>
            Quick add
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", py: 2, width: "100%" }}>
            {starredFeedstock &&
              starredFeedstock.map((biomassRow) => (
                <Button key={biomassRow.id} onClick={() => addBiomassProduct(biomassRow)} disabled={daylist.isLocked}>
                  <Box className="starred-bmp" sx={{ p: 1, width: 100, m: 1 }}>
                    <Typography variant="caption"> {biomassRow.label}</Typography>
                  </Box>
                </Button>
              ))}
          </Box>
        </Box>
      )}
    </>
  );
};
export default StarredBiomasses;
