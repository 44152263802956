import axios from "../services/axios";
import { BASE_URL } from "../services/base";

type dataTypeString = "incomingpos" | "outgoingpos" | "massbalances" | "importedoutgoingpos";
type onDownloadProps = {
  format: "pdf" | "xlsx";
  dataType: dataTypeString;
  id: string;
  fileName: string;
};

export const onDownloadFile = async ({ format, dataType, id, fileName }: onDownloadProps) => {
  const url = `${BASE_URL}/${dataType}/${id}/documents/${format}`;
  const { data } = await axios.get(url);

  const link = document.createElement("a");
  link.href = data.data;
  link.download = `${fileName}.${format}`;
  link.click();
};

export const onDownloadTransit = async ({ format, id, fileName }: Omit<onDownloadProps, "dataType">) => {
  const url = `${BASE_URL}/outgoingpos/${id}/documents/transit/${format}`;
  const { data } = await axios.get(url);

  const link = document.createElement("a");
  link.href = data.data;
  link.download = `${fileName}.${format}`;
  link.click();
};
