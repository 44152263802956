import { Box, Checkbox, IconButton, Tooltip, Typography, useTheme, FormControlLabel, FormGroup } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Delete } from "@mui/icons-material";
import { useFetchSubstrates, useDeleteSubstrate, useUpdateSubstrate } from "../../../hooks/substrates";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { NumberFormatter, FRONTEND_NUMBER_FORMAT, BACKEND_NUMBER_FORMAT } from "../../../utils/NumberFormatter";
import { toast } from "react-toastify";
import { SubstrateListResponse } from "../../../types";

type DailyProductionSubstrateTableProps = {
  daylistId: string;
  isLocked: boolean;
};

const DailyProductionSubstrateTable = ({ daylistId, isLocked }: DailyProductionSubstrateTableProps) => {
  const theme = useTheme();
  const { data: substrates = [], isLoading } = useFetchSubstrates(daylistId);
  const { mutate: deleteSubstrateMutation } = useDeleteSubstrate(daylistId);
  const { mutate: updateSubstrateMutation } = useUpdateSubstrate(daylistId);

  const handleRemoveRow = (substrateId: string) => {
    deleteSubstrateMutation(substrateId);
  };

  const handleUpdateSubsrate = (data: SubstrateListResponse) => {
    const payload = {
      id: data.id,
      biomassId: data.bioMass,
      supplierId: data.supplier,
      tonnage: data.tonnage,
      price: data.price,
      potential: data.potential,
      transportType: data.transportType,
      selfDeclaration: data.selfDeclaration,
      isDelivered: data.isDelivered,
    };

    updateSubstrateMutation(payload);
  };

  const handleAddToProduction = (data: SubstrateListResponse) => {
    const payload = {
      id: data.id,
      biomassId: data.bioMass,
      supplierId: data.supplier,
      tonnage: data.tonnage,
      price: data.price,
      potential: data.potential,
      transportType: data.transportType,
      selfDeclaration: data.selfDeclaration,
      isDelivered: !data.isDelivered,
    };
    updateSubstrateMutation(payload);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ color: theme.palette.primary.main, minHeight: 45 }}>
        Feedstock list
      </Typography>
      {!!substrates && (
        <Box sx={{ maxHeight: "750px", flex: 1, p: 1 }}>
          <DataGrid
            rows={substrates ?? []}
            getRowClassName={(params) => {
              if (isLocked || params.row.isDelivered) {
                return `RowAddedToProduction`;
              }
              return "";
            }}
            columns={[
              {
                field: "bioMassRaw",
                headerName: "Biomass",
                minWidth: 170,
                maxWidth: 200,
                editable: false,
                flex: 1,
                renderCell: (params) => <Typography>{params.row.bioMassRaw}</Typography>,
              },
              {
                field: "supplierRaw",
                headerName: "Supplier",
                minWidth: 140,
                maxWidth: 160,
                editable: false,
                flex: 1,
                renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
              },
              {
                field: "tonnage",
                headerName: "Ton",
                minWidth: 60,
                maxWidth: 70,
                editable: !isLocked,
                flex: 1,
                preProcessEditCellProps: (params) => {
                  if (isLocked || params.row.isDelivered) {
                    toast.error("Can't update delivered substrate");
                    return params;
                  }
                  params.row.tonnage = NumberFormatter(params.props.value, BACKEND_NUMBER_FORMAT);
                  handleUpdateSubsrate(params.row);
                  return params;
                },
                renderCell: (params) => (
                  <Typography variant="caption" color="secondary">
                    {NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 1)}
                  </Typography>
                ),
              },
              {
                field: "price",
                headerName: "Price",
                minWidth: 100,
                maxWidth: 110,
                editable: !isLocked,
                flex: 1,
                preProcessEditCellProps: (params) => {
                  if (isLocked || params.row.isDelivered) {
                    toast.error("Can't update delivered substrate");
                    return params;
                  }
                  params.row.price = NumberFormatter(params.props.value, BACKEND_NUMBER_FORMAT);
                  handleUpdateSubsrate(params.row);
                  return params;
                },
                renderCell: (params) => (
                  <Typography variant="caption" color="secondary">
                    {NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 1)} EUR/ton
                  </Typography>
                ),
              },
              {
                field: "potential",
                headerName: "Biomethane Potential",
                minWidth: 130,
                maxWidth: 150,
                editable: !isLocked,
                flex: 1,
                preProcessEditCellProps: (params) => {
                  if (isLocked || params.row.isDelivered) {
                    toast.error("Can't update delivered substrate");
                    return params;
                  }
                  params.row.potential = NumberFormatter(params.props.value, BACKEND_NUMBER_FORMAT);
                  handleUpdateSubsrate(params.row);
                  return params;
                },
                renderCell: (params) => (
                  <Typography variant="caption" color="secondary">
                    {NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 1)} m3 CH4/ton
                  </Typography>
                ),
              },
              {
                field: "expectedProduction",
                headerName: "Expected gas",
                minWidth: 110,
                maxWidth: 120,
                editable: false,
                flex: 1,
                renderCell: (params) => (
                  <Typography variant="caption">
                    <strong>{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 2)} m3</strong>
                  </Typography>
                ),
              },
              {
                field: "gasPrice",
                headerName: "Price per m3",
                minWidth: 130,
                maxWidth: 140,
                editable: false,
                flex: 1,
                renderCell: (params) => (
                  <Typography variant="caption">
                    <strong>{NumberFormatter(params.value, FRONTEND_NUMBER_FORMAT, 2)} EUR/m3</strong>
                  </Typography>
                ),
              },
              {
                field: "selfDeclaration",
                headerName: "Self-Declaration",
                align: "center",
                minWidth: 180,
                maxWidth: 190,
                flex: 1,
                renderCell: (params) => (
                  <FormGroup>
                    <Tooltip title={isLocked ? "This day is locked" : ""}>
                      <FormControlLabel
                        disabled={isLocked || params.row.isDelivered}
                        control={
                          <Checkbox
                            checked={!!params.row.selfDeclaration}
                            onChange={(event) => handleUpdateSubsrate({ ...params.row, selfDeclaration: event.target.checked })}
                          />
                        }
                        label=""
                      />
                    </Tooltip>
                  </FormGroup>
                ),
              },
              {
                field: "isDelivered",
                headerName: "Add to Production",
                align: "center",
                minWidth: 180,
                maxWidth: 190,
                flex: 1,
                renderCell: (params) => (
                  <FormGroup>
                    <Tooltip title={isLocked ? "This day is locked" : "Add to production"}>
                      <FormControlLabel
                        disabled={isLocked}
                        control={
                          <Checkbox checked={isLocked || params.row.isDelivered} onChange={() => handleAddToProduction(params.row)} />
                        }
                        label="Add to production"
                      />
                    </Tooltip>
                  </FormGroup>
                ),
              },
              {
                field: "actions",
                headerName: "",
                flex: 0.5,
                align: "right",
                filterable: false,
                disableColumnMenu: true,
                editable: false,
                sortable: false,
                renderCell: (params) => (
                  <>
                    <Tooltip title={params.row.isDelivered ? "Already added to Production " : "Remove Substrate"}>
                      <span>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          sx={{ mr: 0.5 }}
                          disabled={isLocked || params.row.isDelivered}
                          onClick={() => handleRemoveRow(params.row.id)}>
                          <Delete />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </>
                ),
              },
            ]}
            initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
            density="compact"
            hideFooterSelectedRowCount
            isRowSelectable={() => false}
            rowHeight={69}
            autoHeight
            sx={{
              flex: 0,
              border: 0,

              [`& .MuiDataGrid-cell `]: {
                borderBottom: "none",
                "&:focus-within": {
                  outline: "none",
                },
              },
              [`& .Mui-selected `]: {
                background: theme.palette.mode === "dark" ? "#3d6b3f !important" : "none",
              },
              [`& .MuiDataGrid-row`]: {
                borderRadius: 2,
                [`&:hover`]: {
                  background: theme.palette.mode === "dark" ? "#333333 !important" : "#f5f5f5 !important",
                },
              },
              [`.RowAddedToProduction`]: {
                [`&:hover`]: {
                  background: theme.palette.mode === "dark" ? "#2b7c3d !important" : "#5fb878 !important",
                },
              },
              [`& .MuiDataGrid-columnSeparator `]: {
                display: "none",
              },
              "& .RowAddedToProduction": {
                background:
                  theme.palette.mode === "dark" ? `${theme.palette.primary.dark} !important` : `${theme.palette.primary.light} !important`,
              },
            }}
          />
        </Box>
      )}

      {!isLoading && !substrates && (
        <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
          <Typography>No substrates for this day yet.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default DailyProductionSubstrateTable;
