import { Box, Container} from "@mui/material";
import SignIn from "../components/Signin";
import { useAuth } from "../hooks/useAuth";

const Landingpage = () => {
  const { isAuthenticated } = useAuth();

  return (
    <Container>
      {isAuthenticated && localStorage.getItem("user") == "error" ? (
        ""
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <SignIn />
        </Box>
      )}
    </Container>
  );
};

export default Landingpage;
