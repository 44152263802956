import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useFetchBathtubSummary } from "../../hooks/storage";
import { DateFormatter } from "../../utils/DateFormatter";
import InputFormDatePicker from "../../components/form-components/InputFormDatePicker";
import { useNavigate } from "react-router-dom";
import { InputFormCheckbox } from "../../components/form-components/InputFormCheckbox";
import { BathTubSummaryRequest, BathTubSummaryResponse } from "../../types";

type SubmitValues = {
  from: string;
  to: string;
  redOnly: boolean;
};
const defaultValues = {
  from: "",
  to: "",
  redOnly: false,
};

const SummaryLog = () => {
  const [summaryData, setSummaryData] = useState<BathTubSummaryResponse>();
  const methods = useForm<SubmitValues>({ defaultValues });
  const { handleSubmit, control, reset } = methods;
  const { mutate: getSummary, isPending } = useFetchBathtubSummary();
  const theme = useTheme();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<SubmitValues> = (values) => {
    const requestData: BathTubSummaryRequest = {
      ...values,
      from: DateFormatter(values.from),
      to: DateFormatter(values.to),
    };

    getSummary(requestData, {
      onSuccess: (data) => {
        setSummaryData(data);
      },
    });
  };

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
          Summary
        </Typography>
      </Box>

      <Box sx={{ p: 2, mt: 1 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: 2 }}>
            <Box sx={{ display: "flex", gap: 3, mr: 4, alignItems: "center" }}>
              <InputFormDatePicker name="from" control={control} label="From" required={true} unsetMargin />
              <InputFormDatePicker name="to" control={control} label="To" required={true} unsetMargin />
              <InputFormCheckbox name="redOnly" control={control} label="RED only" />
            </Box>
            {isPending ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Button
                  variant="text"
                  color="warning"
                  onClick={() => {
                    setSummaryData(undefined);
                    reset();
                  }}>
                  Reset
                </Button>
                <Button variant="contained" color="success" type="submit">
                  Search
                </Button>
              </>
            )}
          </Box>
        </form>
        <Divider sx={{ m: 2, bgcolor: theme.palette.primary.main }} />
        {!summaryData ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 3 }}>
            <Typography variant="body1">Select dates to get a summary. </Typography>{" "}
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <Box sx={{ display: "flex", gap: 4, justifyContent: "center" }}>
              <Box minWidth={200}>
                <Typography variant="caption">Incoming - MWh</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6" color={theme.palette.warning.main}>
                  {summaryData?.incomingKWh && Math.trunc(summaryData.incomingKWh / 1000)}
                </Typography>
              </Box>
              <Box minWidth={200}>
                <Typography variant="caption">Outgoing - MWh</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6" color={theme.palette.warning.main}>
                  {summaryData?.outgoingKWh && Math.trunc(summaryData.outgoingKWh / 1000)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 4, justifyContent: "center" }}>
              <Box minWidth={200}>
                <Typography variant="caption">Difference</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6"> {summaryData?.differenceKWh && Math.trunc(summaryData.differenceKWh / 1000)}</Typography>
              </Box>
              <Box minWidth={200}>
                <Typography variant="caption">In Stock - MWh</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Typography variant="h6" color={theme.palette.info.dark}>
                  {summaryData?.bathTubStockKWh && Math.trunc(summaryData.bathTubStockKWh / 1000)}
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", gap: 4, justifyContent: "center", my: 4 }}>
              <Box>
                <Typography variant="caption">Incoming PoS list</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="body2">{summaryData?.incoming && summaryData.incoming.length} total incoming PoS</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {summaryData?.incoming &&
                          summaryData.incoming.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography variant="caption">{item}</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box>
                <Typography variant="caption">Outgoing PoS list</Typography>
                <Divider sx={{ bgcolor: theme.palette.primary.main }} />
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Typography variant="body2">{summaryData?.outgoing && summaryData.outgoing.length} total outgoing PoS</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {summaryData?.outgoing &&
                          summaryData.outgoing.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography variant="caption">{item}</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", mb: 3, mt: 1 }}>
        <Button variant="text" color="info" onClick={() => navigate("/storage")}>
          Go back to storage
        </Button>
      </Box>
    </Container>
  );
};

export default SummaryLog;
