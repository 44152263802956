import { useEffect, useState } from "react";
import { CreateUserForm, RoleCategory, UserRequest, UserResponse } from "../../../types";
import { useForm } from "react-hook-form";
import { useUpdateUser } from "../../../hooks/users";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import UserForm from "./UserForm";

const defaultValues: CreateUserForm = {
  name: "",
  email: "",
  plantId: null,
  customerId: null,
  userType: 3,
};

type UpdateUserProps = {
  selectedUser: UserResponse;
  showEdit: (value: boolean) => void;
};

const UpdateUser = ({ selectedUser, showEdit }: UpdateUserProps) => {
  const [loading, setLoading] = useState(false);
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, control, reset, resetField, formState, clearErrors, getValues } = methods;
  const { mutate: updateUser } = useUpdateUser();

  useEffect(() => {
    const defaultValues: CreateUserForm = {
      name: selectedUser.name,
      email: selectedUser.email,
      plantId: selectedUser.plantId,
      customerId: selectedUser.customerId,
      userType: selectedUser.plantId ? RoleCategory.PlantOwner : RoleCategory.Customer,
    };
    reset(defaultValues);
  }, [selectedUser, reset]);

  const onSubmit = (submittedValue: CreateUserForm) => {
    setLoading(true);

    const requestData: UserRequest = {
      ...submittedValue,
      plantId: submittedValue.plantId ? submittedValue.plantId : null,
      customerId: submittedValue.customerId ? submittedValue.customerId : null,
      role: selectedUser.role === "Admin" ? RoleCategory.Admin : submittedValue.userType,
    };

    updateUser(
      { id: selectedUser.id, updatedUser: requestData },
      {
        onSuccess() {
          showEdit(false);
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 2, my: 2 }}>
        <Typography variant="h6">Update customer</Typography>
        <UserForm control={control} resetField={resetField} errors={formState.errors} clearErrors={clearErrors} getValues={getValues} />
        <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
          <Button variant="text" color="secondary" onClick={() => showEdit(false)}>
            Cancel
          </Button>
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button variant="contained" color="success" type="submit">
              Save
            </Button>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default UpdateUser;
