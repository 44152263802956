/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import useLocalStorage from "./useLocalStorage";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../services/axios";
import { ADMIN_ROLE, PLANT_OWNER_ROLE, BASE_URL, CUSTOMER_ROLE } from "../services/base";
import { useMsal } from "@azure/msal-react";
import { UserResponse } from "../types";

interface IAuthContext {
  isAuthenticated: boolean;
  user: User | null;
  showError: boolean;
  onLogout: () => void;
  setAndGetUserFromBackend: (id: string, loginRequest: typeof ADMIN_ROLE | typeof PLANT_OWNER_ROLE) => void;
  setLoginRequestRole: (role: typeof ADMIN_ROLE | typeof PLANT_OWNER_ROLE) => void;
  loginRequestRole: typeof ADMIN_ROLE | typeof PLANT_OWNER_ROLE | null;
}

const initialConfig = {
  isAuthenticated: localStorage.getItem("user") !== null && localStorage.getItem("user") !== "error",
  user: null,
  showError: false,
  onLogout: () => {},
  setAndGetUserFromBackend: () => {},
  setLoginRequestRole: () => {},
  loginRequestRole: (localStorage.getItem("role") as typeof ADMIN_ROLE | typeof PLANT_OWNER_ROLE) ?? null,
};

const AuthContext = createContext<IAuthContext>(initialConfig);

export interface User {
  id: string;
  email: string;
  name: string;
  role: string;
  plantId?: string;
  customerId?: string;
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [localStorageUser, setLocalStorageUser] = useLocalStorage("user", null);
  const [localStorageRole, setLocalStorageRole] = useLocalStorage("role", null);
  const [isAuthenticated] = useState(() => {
    return localStorageUser !== null && localStorageUser != "error";
  });
  const [user, setUser] = useState<User | null>(null);
  const [showError, setShowError] = useState(false);
  const { instance } = useMsal();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      // REFRESH
      setUser(localStorageUser);
      navigate(`${location.pathname}`);
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (location.pathname == "/") {
        if (user.role === ADMIN_ROLE) navigate("/massbalances");
        if (user.role === PLANT_OWNER_ROLE) navigate("/daily-production");
      }
    }
  }, [user]);

  const fetchUser = async (id: string): Promise<UserResponse | null> => {
    try {
      const { data } = await axios.get(`${BASE_URL}/users/${id}`);
      return data.data;
    } catch (err) {
      setShowError(true);
      setUser(null);
      setLocalStorageUser(null);
      return null;
    }
  };

  const setAndGetUserFromBackend = async (id: string, loginRequest: "PlantOwner" | "Admin") => {
    const usr = await fetchUser(id);

    if (!usr) {
      setShowError(true);
      setUser(null);
      setLocalStorageUser("error");
      return;
    }

    if (loginRequest === PLANT_OWNER_ROLE && (!usr.plantId || usr.role === CUSTOMER_ROLE || usr.customerId)) {
      setShowError(true);
      setUser(null);
      setLocalStorageUser("error");
      return;
    }

    if (loginRequest === ADMIN_ROLE && usr.role !== ADMIN_ROLE) {
      setShowError(true);
      setUser(null);
      setLocalStorageUser("error");
      return;
    }

    usr.role = loginRequest;
    setUser(usr);
    setLocalStorageUser(usr);
  };

  const handleLogout = () => {
    setLocalStorageUser(null);
    setLocalStorageRole(null);
    instance.logoutRedirect();
  };

  const setLoginRequestRole = (role: "PlantOwner" | "Admin") => {
    setLocalStorageRole(role);
  };

  const value = {
    isAuthenticated,
    user,
    showError,
    onLogout: handleLogout,
    setAndGetUserFromBackend,
    setLoginRequestRole,
    loginRequestRole: localStorageRole,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
