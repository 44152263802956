import { Box, Container, Divider, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import OutgoingPosTable from "../../components/tables/OutgoingPosTable";
import { useFetchImportedOutgoingPos, useFetchOutgoingPos } from "../../hooks/outgoingPos";
import { AddCircle, Restore, Update } from "@mui/icons-material";
import { useState } from "react";
import ImportOldPos from "../components/ImportOldPos";
import ImportedOutgoingPosTable from "../../components/tables/ImportedOutgoingPosTable";

const SoldPos = () => {
  const { data: posData, isLoading, isError } = useFetchOutgoingPos();
  const { data: oldPosData } = useFetchImportedOutgoingPos();
  const theme = useTheme();
  const [showCreate, setShowCreate] = useState(false);
  const [showOld, setShowOld] = useState(false);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Sold PoS
        </Typography>
        {showOld && !showCreate && (
          <Tooltip title="Create new PoS">
            <IconButton aria-label="create" color="primary" onClick={() => setShowCreate(!showCreate)}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        )}

        {showOld ? (
          <Tooltip title="Hide imported PoSes">
            <IconButton aria-label="create" color="primary" onClick={() => setShowOld(!showOld)}>
              <Update />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Show imported PoSes">
            <IconButton aria-label="create" color="primary" onClick={() => setShowOld(!showOld)}>
              <Restore />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {showCreate && <ImportOldPos showCreate={(value: boolean) => setShowCreate(value)} />}
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      {showOld ? (
        <Box sx={{ mt: 2 }}>{oldPosData ? <ImportedOutgoingPosTable data={oldPosData} /> : "No data were found"}</Box>
      ) : (
        <Box sx={{ mt: 2 }}>{posData ? <OutgoingPosTable data={posData} /> : "No data were found"}</Box>
      )}
    </Container>
  );
};

export default SoldPos;
