import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetchBathtub } from "../../hooks/storage";
import { Box, Container, Typography, useTheme, Divider, IconButton, Menu, MenuItem, TextField, InputAdornment } from "@mui/material";
import { MoreVert, ListAlt, ManageSearch } from "@mui/icons-material";
import CertificateStorageTable from "../../components/tables/CertificateStorageTable";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import { createFilter } from "../../utils/FilterData";

const CertificateStorage = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const { data, isLoading, isError } = useFetchBathtub();
  const [filterText, setFilterText] = useState<string>("");
  const theme = useTheme();
  const navigate = useNavigate();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Certificate Storage
        </Typography>
        <TextField
          label="Filter"
          onChange={(e) => setFilterText(e.target.value)}
          value={filterText}
          variant="outlined"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ManageSearch />
              </InputAdornment>
            ),
          }}
        />
        <Box>
          <IconButton
            id="more-button"
            aria-label="more"
            aria-controls={openMenu ? "more-action-menu" : undefined}
            aria-expanded={openMenu ? "true" : undefined}
            onClick={handleMenu}
            color="primary">
            <MoreVert />
          </IconButton>
          <Menu id="more-action-menu" anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
            <MenuItem onClick={() => navigate("/storage/audit")}>
              <ListAlt sx={{ mr: 1 }} color="primary" />
              Summary log
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ mt: 2 }}>{data ? <CertificateStorageTable data={data.filter(createFilter(filterText))} /> : "No data were found"}</Box>
    </Container>
  );
};

export default CertificateStorage;
