import { useLocation } from "react-router-dom";
import { useState } from "react";
import { Box, Typography, Tooltip, IconButton, useTheme, Divider } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ShowError from "../../../components/ShowError";
import MonthlyReportTable from "../../../components/tables/MonthlyReportTable";
import CreateProductionData from "./CreateProductionData";
import { useFetchMonthlyProduction } from "../../../hooks/plant";

type Props = {
  plantId: string;
};

const ListProductionData = ({ plantId }: Props) => {
  const location = useLocation();
  const [showCreate, setShowCreate] = useState(location?.state?.showCreate || false);
  const theme = useTheme();
  const { data, isLoading, isError } = useFetchMonthlyProduction(plantId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", minHeight: 45 }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Monthly Production
        </Typography>
        {!showCreate && (
          <Box>
            <Tooltip title="Create new monthly report">
              <IconButton aria-label="create" color="primary" onClick={() => setShowCreate(true)}>
                <AddCircle />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      {showCreate && <CreateProductionData plantId={plantId} showCreate={(value: boolean) => setShowCreate(value)} />}
      <Divider sx={{ my: 1, bgcolor: theme.palette.primary.main }} />
      {data && <MonthlyReportTable data={data} plantId={plantId} />}
    </>
  );
};

export default ListProductionData;
