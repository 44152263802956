import { Box, Button, MenuItem, Select, Divider, Grid, Typography, CircularProgress, useTheme, Alert } from "@mui/material";
import { DateFormatter } from "../../../utils/DateFormatter";
import { FRONTEND_NUMBER_FORMAT, FRONTEND_PERCENT_FORMAT, NumberFormatter } from "../../../utils/NumberFormatter";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useFetchPlants, useAddPlantMassbalance, useFetchMonthlyProduction } from "../../../hooks/plant";
import { useFetchSubstrateMonthLists } from "../../../hooks/substrates";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import InputFormDatePicker from "../../../components/form-components/InputFormDatePicker";
import { AxiosError } from "axios";

type CreateMassBalanceDetailsProps = {
  plantSelected: string;
  onUpdateHasMassBalance: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: Date;
};

const CreateMassBalanceDetails = ({ plantSelected, selectedDate, onUpdateHasMassBalance }: CreateMassBalanceDetailsProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const productionDatas = useFetchMonthlyProduction(plantSelected);
  const substrateLists = useFetchSubstrateMonthLists(plantSelected);

  const producedAt = DateFormatter(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).toString());
  const productionData = productionDatas.data?.find((item) => item.producedAt == producedAt);
  const substrateList = substrateLists.data?.find((item) => item.producedAt == producedAt);

  onUpdateHasMassBalance && onUpdateHasMassBalance(!!substrateList?.massBalanceId);

  return (
    <>
      <Box sx={{ pl: 2 }}>
        <Typography variant="h6">Production data</Typography>
        {productionData && (
          <Grid container rowSpacing={1} sx={{ p: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <Typography variant="body2">Certificates</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{NumberFormatter(productionData.certificatesKWh, FRONTEND_NUMBER_FORMAT, 0)} kWh</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Other</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{NumberFormatter(productionData.otherUsageKWh, FRONTEND_NUMBER_FORMAT, 0)} kWh</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Flare</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{NumberFormatter(productionData.torchKWh, FRONTEND_NUMBER_FORMAT, 0)} kWh</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Methane loss</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{NumberFormatter(productionData.methaneLoss, FRONTEND_PERCENT_FORMAT, 2)} %</Typography>
            </Grid>
          </Grid>
        )}
        {!productionData && (
          <Box sx={{ py: 2 }}>
            <Typography variant="body2">Data does not exist for given period.</Typography>
            <Button
              variant="text"
              color="warning"
              onClick={() => navigate(`/plants/${plantSelected}/productions`, { state: { showCreate: true, selectedDate: selectedDate } })}>
              Add
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ pl: 2 }}>
        <Typography variant="h6">Substrate list</Typography>
        {substrateList && (
          <Grid container rowSpacing={1} sx={{ py: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <Typography variant="body2">Count</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">{substrateList.substratesCount} substrates</Typography>
            </Grid>
          </Grid>
        )}
        {!substrateList && (
          <Box sx={{ py: 2 }}>
            <Typography variant="body2">Data does not exist for given period.</Typography>
            <Button
              variant="text"
              color="warning"
              onClick={() => navigate(`/plants/${plantSelected}/substrates`, { state: { showCreate: true, selectedDate: selectedDate } })}>
              Add
            </Button>
          </Box>
        )}
      </Box>

      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
    </>
  );
};

const defaultValues = {
  substrateFile: null,
};

const CreateMassbalance = () => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("Something went wrong. Please try again later.");

  const { mutate: addMassbalance } = useAddPlantMassbalance();

  const methods = useForm({
    defaultValues: {
      massBalanceMonth: location?.state?.selectedDate || new Date(),
      ...defaultValues,
    },
  });
  const { handleSubmit, control, reset } = methods;
  const [selectedDate, setSelectedDate] = useState<Date>(location?.state?.selectedDate || new Date());
  const [plantSelected, setPlantSelected] = useState<string>(location?.state?.plantSelected || "");
  const [hasMassBalance, setHasMassBalance] = useState(false);

  const plants = useFetchPlants();

  useEffect(() => {
    reset();
  }, [plantSelected, reset]);

  useEffect(() => {
    setErrorMessage("");
  }, [plantSelected, selectedDate]);

  const onSubmit = async (values: { massBalanceMonth: Date }) => {
    setLoading(true);

    const massBalanceMonthDate = new Date(values.massBalanceMonth);
    const producedAt = DateFormatter(new Date(massBalanceMonthDate.getFullYear(), massBalanceMonthDate.getMonth(), 1).toString());

    const requestData = {
      plantId: plantSelected,
      producedAt: producedAt,
    };

    addMassbalance(requestData, {
      onError: (error: unknown) => {
        setLoading(false);
        if (error instanceof AxiosError) {
          setErrorMessage(error?.response?.data.detail);
        }
      },
      onSuccess: (response) => {
        toast.success("Massbalance successfully created!");
        setLoading(false);
        reset();
        navigate(`/massbalances/${response.data.data.id}`);
      },
    });
  };

  const generateSelectOptions = () => {
    if (plants.data) {
      return plants.data.map((option) => {
        return (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        );
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ pl: 2, pt: 2 }}>
                <Box>
                  <Typography variant="body1">Select plant, month and year to create Mass Balance</Typography>
                </Box>
              </Box>
              <Box sx={{ p: 2 }}>
                <Select
                  onChange={(event) => setPlantSelected(event.target.value as string)}
                  value={plantSelected}
                  displayEmpty
                  defaultValue="Select Plant"
                  sx={{ height: 45 }}>
                  <MenuItem value="">Select Plant</MenuItem>
                  {generateSelectOptions()}
                </Select>
              </Box>
              <Box sx={{ p: 2 }}>
                <InputFormDatePicker
                  name="massBalanceMonth"
                  control={control}
                  label="Select month"
                  required={true}
                  onChange={setSelectedDate}
                  format="MM/yyyy"
                  errorText="Month and year is required"
                  formatViews={["month", "year"]}
                  unsetMargin
                />
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
          {plantSelected && selectedDate && (
            <CreateMassBalanceDetails
              plantSelected={plantSelected}
              selectedDate={selectedDate}
              onUpdateHasMassBalance={setHasMassBalance}
            />
          )}

          <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
            {loading ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Button variant="contained" color="success" type="submit" disabled={!plantSelected || !selectedDate || hasMassBalance}>
                Save
              </Button>
            )}
          </Box>

          {errorMessage && (
            <Box>
              <Alert severity="error">
                <Typography variant="body2">{errorMessage}</Typography>
              </Alert>
            </Box>
          )}

          {hasMassBalance && (
            <Box>
              <Alert severity="warning">Mass Balance is already created for given period.</Alert>
            </Box>
          )}
        </Box>
      </form>
    </>
  );
};

export default CreateMassbalance;
