import { useAuth } from "../../hooks/useAuth";
import ListPotential from "./components/ListPotential";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";

const PlantPotentials = () => {
    const { id } = useParams() as { id: string };
    const { user } = useAuth();

    return (
      <Container>
        <ListPotential plantId={id ?? user?.plantId} />
      </Container>
    );
};

export default PlantPotentials;