import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { defaultTableStyle } from "../../components/themes/tableStyle";
import { BioMassProductResponse } from "../../types";

type Props = {
  data: BioMassProductResponse[];
};

const AdminBioMassPotentialTable = ({ data }: Props) => {
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ height: 750, maxWidth: "100%" }}>
        <DataGrid
          rows={data}
          slots={{
            toolbar: GridToolbar,
          }}
          columns={[
            {
              field: "label",
              headerName: "Label",
              minWidth: 120,
              maxWidth: 140,
              flex: 1,
              type: "string",
              editable: false,
              renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
            },
            {
              field: "bioMass",
              headerName: "Biomass",
              minWidth: 100,
              maxWidth: 150,
              flex: 1,
              type: "string",
              editable: false,
              valueGetter: (params) => params.row.bioMass.name,
            },
            {
              field: "category",
              headerName: "Category",
              minWidth: 100,
              maxWidth: 150,
              flex: 1,
              type: "string",
              editable: false,
              valueGetter: (params) => params.row.bioMass.category,
            },
            {
              field: "potential",
              headerName: "Biomethane Potential (Default)",
              minWidth: 160,
              maxWidth: 220,
              flex: 1,
              type: "string",
              editable: false,
              renderCell: (params) => {
                return (
                  <Typography
                    variant="caption"
                    sx={
                      params.row.potential == params.row.bioMass.defaultPotential
                        ? { fontWeight: "bold" }
                        : { color: theme.palette.error.main }
                    }>
                    {NumberFormatter(params.row.potential, FRONTEND_NUMBER_FORMAT, 1)}{" "}
                    {params.row.potential != params.row.bioMass.defaultPotential && (
                      <Tooltip title={"Biomethane Potential (Default)"}>
                        <span style={{ color: theme.palette.text.primary }}>
                          ({NumberFormatter(params.row.bioMass.defaultPotential, FRONTEND_NUMBER_FORMAT, 1)})
                        </span>
                      </Tooltip>
                    )}
                  </Typography>
                );
              },
            },
            {
              field: "pricePerTonnage",
              headerName: "Price per Tonnage",
              minWidth: 100,
              maxWidth: 150,
              flex: 1,
              type: "string",
              editable: false,
              renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
            },

            {
              field: "supplier",
              headerName: "Supplier",
              minWidth: 100,
              maxWidth: 200,
              flex: 1,
              type: "string",
              editable: false,
              valueGetter: (params) => params.row.supplier.owner,
            },
          ]}
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
          density="compact"
          isRowSelectable={() => false}
          hideFooterSelectedRowCount
          sx={defaultTableStyle}
        />
      </Box>
    </Box>
  );
};

export default AdminBioMassPotentialTable;
