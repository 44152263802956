import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  DataGrid,
  GridRowParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DateFormatter, FRONTEND_MONTH_FORMAT } from "../../utils/DateFormatter";
import { FRONTEND_NUMBER_FORMAT, FRONTEND_PERCENT_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import LoadingSpinner from "../LoadingSpinner";
import { MassBalanceResponse, PosResponse } from "../../types";

type MassbalanceTableProps = {
  data: MassBalanceResponse[];
  isAdmin?: boolean;
  incomingPosData?: { incomingPosList: PosResponse[]; isPosListLoading: boolean };
};

const MassbalanceTable = ({ data, isAdmin, incomingPosData }: MassbalanceTableProps) => {
  const [showOnlyAdded, setShowOnlyAdded] = useState<boolean | null>(null);
  const [filteredData, setFilteredData] = useState<MassBalanceResponse[]>([]);
  const { incomingPosList, isPosListLoading } = { ...incomingPosData };
  const theme = useTheme();
  const navigate = useNavigate();

  const showFilteredData = () => {
    if (showOnlyAdded) {
      const res = data.filter(({ id }) => incomingPosList?.some((x) => x.massBalanceId === id));
      setFilteredData(res);
    }
    if (!showOnlyAdded) {
      const res = data.filter(({ id }) => !incomingPosList?.some((x) => x.massBalanceId === id));
      setFilteredData(res);
    }
  };

  function CustomToolbar() {
    return (
      <Box sx={{ display: "flex", m: 1 }}>
        <GridToolbarContainer sx={{ flexGrow: 1 }}>
          <GridToolbarColumnsButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
          <GridToolbarFilterButton nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
          <GridToolbarDensitySelector nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
        </GridToolbarContainer>
        {isAdmin && (
          <Box p={1}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => {
                setShowOnlyAdded(!showOnlyAdded);
                showFilteredData();
              }}
              sx={{ mr: 1 }}>
              {showOnlyAdded ? "Show only added" : "Show only non added"}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowOnlyAdded(null)}
              size="small"
              disabled={showOnlyAdded === null}>
              Show all
            </Button>
          </Box>
        )}
      </Box>
    );
  }

  if (isPosListLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      <DataGrid
        components={{ Toolbar: CustomToolbar }}
        rows={showOnlyAdded !== null ? filteredData : data}
        onRowClick={(params: GridRowParams) => navigate(`/massbalances/${params.row.id}`)}
        getRowClassName={(params) =>
          incomingPosList && !incomingPosList.find((item) => item.massBalanceId === params.id)
            ? "NotAddedToIncomingPos"
            : "AddedToIncomingPos"
        }
        columns={[
          {
            field: "periodStart",
            headerName: "Produced",
            minWidth: 120,
            maxWidth: 140,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{DateFormatter(params.value, FRONTEND_MONTH_FORMAT)}</Typography>,
          },
          {
            field: "plantName",
            headerName: "Plant",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "theoreticalNetProductionKWh",
            headerName: "Theoretical Production",
            minWidth: 100,
            maxWidth: 250,
            flex: 1,
            type: "number",
            editable: false,
            renderCell: (params) => (
              <Typography variant="caption">
                <strong>{NumberFormatter(params.value / 1000, FRONTEND_NUMBER_FORMAT, 0)} MWh</strong>
              </Typography>
            ),
          },
          {
            field: "actualNetProductionKWh",
            headerName: "Actual Production",
            minWidth: 100,
            maxWidth: 250,
            flex: 1,
            type: "number",
            editable: false,
            renderCell: (params) => (
              <Typography variant="caption">
                <strong>{NumberFormatter(params.value / 1000, FRONTEND_NUMBER_FORMAT, 0)} MWh</strong>
              </Typography>
            ),
          },
          {
            field: "correctionFactor",
            headerName: "Correction Factor",
            minWidth: 100,
            flex: 0.5,
            type: "number",
            editable: false,
            renderCell: (params) => (
              <Typography variant="caption">
                <strong>{NumberFormatter(params.value, FRONTEND_PERCENT_FORMAT, 2)}%</strong>
              </Typography>
            ),
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        isRowSelectable={() => false}
        hideFooterSelectedRowCount
        sx={{
          [`& .AddedToIncomingPos `]: {
            background:
              theme.palette.mode === "dark" ? `${theme.palette.primary.dark} !important` : `${theme.palette.primary.light} !important`,
          },
          border: 0,
          [`& .MuiDataGrid-cell `]: {
            borderBottom: "none",
            "&:focus-within": {
              outline: "none",
            },
          },
          [`& .Mui-selected `]: {
            background: theme.palette.mode === "dark" ? "#3d6b3f !important" : "none",
          },
          [`& .MuiDataGrid-row `]: {
            background: theme.palette.mode === "dark" ? "#333333" : "none",
            my: 0.3,
            borderRadius: 1,
            maxWidth: "100%",
            [`&:hover`]: {
              background: theme.palette.mode === "dark" ? "#333333 !important" : "#f5f5f5 !important",
            },
          },
          [`& .MuiDataGrid-columnSeparator `]: {
            display: "none",
          },
        }}
      />
    </Box>
  );
};

export default MassbalanceTable;
