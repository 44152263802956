import { Divider, Box, useTheme } from "@mui/material";
import InputFormText from "../../../components/form-components/InputFormText";
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormResetField,
  ValidateResult,
  useWatch,
} from "react-hook-form";
import { CreateUserForm, RoleCategory } from "../../../types/index";
import { InputFormSelect } from "../../../components/form-components/InputFormSelect";
import { useFetchCustomers } from "../../../hooks/customer";
import { useFetchPlants } from "../../../hooks/plant";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { InputFormRadio } from "../../../components/form-components/InputFormRadio";

type UserForm = {
  control: Control<CreateUserForm, unknown>;
  resetField: UseFormResetField<CreateUserForm>;
  errors: FieldErrors<CreateUserForm>;
  clearErrors: UseFormClearErrors<CreateUserForm>;
  getValues: UseFormGetValues<CreateUserForm>;
};

const UserForm = ({ control, resetField, errors, clearErrors, getValues }: UserForm) => {
  const theme = useTheme();
  useWatch({ name: "userType", control });

  const { data: customers, isLoading: customersLoading } = useFetchCustomers();
  const { data: plants, isLoading: plantsLoading } = useFetchPlants();

  if (customersLoading || plantsLoading) {
    return <LoadingSpinner />;
  }

  const customValidation = (_fieldValue: string, fieldValues: FieldValues): ValidateResult => {
    if (fieldValues.userType === RoleCategory.Customer && !fieldValues.customerId) {
      return "You need to select a Customer";
    }

    if (fieldValues.userType === RoleCategory.PlantOwner && !fieldValues.plantId) {
      return "You need to select a Plant";
    }

    return true;
  };

  const getUserTypes = [
    {
      label: "Customer",
      value: RoleCategory.Customer,
    },
    {
      label: "Plant Owner",
      value: RoleCategory.PlantOwner,
    },
  ];

  return (
    <>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ display: "flex", marginX: 2, marginY: 1 }}>
        <InputFormRadio name="userType" control={control} label="User type" data={getUserTypes} horizontalDirection={true} />
      </Box>
      <Box sx={{ display: "flex" }}>
        <InputFormText name="name" control={control} label="Name" required errorText={errors?.name?.message || "Name is required"} />
        <InputFormText
          name="email"
          type="email"
          control={control}
          label="Email"
          pattern={{ value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address" }}
          required
          errorText={errors?.email?.message || "Email is required"}
          onChange={() => {
            if (errors && errors.email) {
              clearErrors("email");
            }
          }}
        />
      </Box>

      <Box sx={{ display: "flex" }}>
        {Number(getValues("userType")) === RoleCategory.Customer && (
          <InputFormSelect
            name="customerId"
            control={control}
            label="Customer"
            resetField={() => {
              resetField("customerId", { defaultValue: null });
            }}
            customValidation={customValidation}
            data={customers?.map((customer) => ({ id: customer.id, name: customer.name }))}
            errorText={errors.customerId?.message}
          />
        )}
        {Number(getValues("userType")) === RoleCategory.PlantOwner && (
          <InputFormSelect
            name="plantId"
            control={control}
            label="Plant"
            resetField={() => {
              resetField("plantId", { defaultValue: null });
            }}
            customValidation={customValidation}
            data={plants?.map((plant) => ({ id: plant.id, name: plant.name }))}
            errorText={errors.plantId?.message}
          />
        )}
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
    </>
  );
};

export default UserForm;
