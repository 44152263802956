import { useState } from "react";
import { Box, Divider, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import LoadingSpinner from "../../../components/LoadingSpinner";
import ShowError from "../../../components/ShowError";
import SuppliersTable from "../../../components/tables/SuppliersTable";
import CreateSupplier from "../../plant-owner/components/CreateSupplier";
import { useFetchSuppliers } from "../../../hooks/suppliers";

type Props = {
  plantId: string;
};

const ListSuppliers = ({ plantId }: Props) => {
  const [showCreate, setShowCreate] = useState(false);
  const theme = useTheme();
  const { data, isLoading, isError } = useFetchSuppliers(plantId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Suppliers
        </Typography>
        {!showCreate && (
          <Box>
            <Tooltip title="Create new supplier">
              <IconButton aria-label="create" color="primary" onClick={() => setShowCreate(true)}>
                <AddCircle />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      {showCreate && <CreateSupplier showCreate={(value: boolean) => setShowCreate(value)} plantId={plantId} />}
      {data && <SuppliersTable data={data} />}
    </>
  );
};

export default ListSuppliers;
