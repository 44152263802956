import { useState } from "react";
import { Box, Button, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import { DeleteForever, ModeEdit, Star, StarBorderOutlined, PostAdd } from "@mui/icons-material";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";
import { useDeletePlantBioMassProducts, useUpdateBioMassProduct } from "../../hooks/biomassproduct";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { defaultTableStyle } from "../themes/tableStyle";
import { BioMassProductResponse } from "../../types";
import AddDailyBioMassPotential from "../../pages/plant/components/AddDailyBioMassPotential";
import EditBioMassPotential from "../../pages/plant/components/EditBioMassPotential";

type BioMassPotentialTableProps = {
  data: BioMassProductResponse[];
};

const BioMassPotentialTable = ({ data }: BioMassPotentialTableProps) => {
  const [showUpdate, setShowUpdate] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [selectedRow, setSelectedRow] = useState<BioMassProductResponse>();
  const { mutate: deleteBioMassProduct } = useDeletePlantBioMassProducts();
  const theme = useTheme();
  const updateBioMass = useUpdateBioMassProduct();

  const handleEditMode = (row: BioMassProductResponse) => {
    setShowUpdate(true);
    setShowAdd(false);
    setSelectedRow(row);
  };

  const handleAddMode = (row: BioMassProductResponse) => {
    setShowAdd(true);
    setShowUpdate(false);
    setSelectedRow(row);
  };

  const handleRemove = (row: BioMassProductResponse) => {
    deleteBioMassProduct(row.id);
  };

  const star = (bioMass: BioMassProductResponse) => {
    bioMass.starred = true;
    updateBioMass.mutate(bioMass);
  };

  const unstar = (bioMass: BioMassProductResponse) => {
    bioMass.starred = false;
    updateBioMass.mutate(bioMass);
  };

  return (
    <Box>
      <Box>
        {showUpdate && selectedRow && <EditBioMassPotential data={selectedRow} showUpdate={(value: boolean) => setShowUpdate(value)} />}
        {showAdd && selectedRow && <AddDailyBioMassPotential data={selectedRow} showAdd={(value: boolean) => setShowAdd(value)} />}
      </Box>
      <Box sx={{ height: 750, maxWidth: "100%" }}>
        <DataGrid
          rows={data}
          slots={{
            toolbar: GridToolbar,
          }}
          columns={[
            {
              field: "starred",
              headerName: "Starred",
              minWidth: 90,
              maxWidth: 120,
              flex: 0.5,
              type: "string",
              editable: false,
              renderCell: (params) => {
                return (
                  <Box>
                    {params.row.starred ? (
                      <Star className="hover" sx={{ color: "#f1c40f" }} onClick={() => unstar(params.row)} />
                    ) : (
                      <StarBorderOutlined className="hover" sx={{ color: "#f1c40f" }} onClick={() => star(params.row)} />
                    )}
                  </Box>
                );
              },
            },
            {
              field: "label",
              headerName: "Label",
              minWidth: 120,
              maxWidth: 140,
              flex: 1,
              type: "string",
              editable: false,
              renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
            },
            {
              field: "bioMass",
              headerName: "Biomass",
              minWidth: 100,
              maxWidth: 150,
              flex: 1,
              type: "string",
              editable: false,
              valueGetter: (params) => params.row.bioMass.name,
            },
            {
              field: "category",
              headerName: "Category",
              minWidth: 100,
              maxWidth: 150,
              flex: 1,
              type: "string",
              editable: false,
              valueGetter: (params) => params.row.bioMass.category,
            },
            {
              field: "potential",
              headerName: "Biomethane Potential (Default)",
              minWidth: 130,
              maxWidth: 210,
              flex: 1,
              type: "string",
              editable: false,
              renderCell: (params) => {
                return (
                  <Typography
                    variant="caption"
                    sx={
                      params.row.potential == params.row.bioMass.defaultPotential
                        ? { fontWeight: "bold" }
                        : { color: theme.palette.error.main }
                    }>
                    {NumberFormatter(params.row.potential, FRONTEND_NUMBER_FORMAT, 1)}{" "}
                    {params.row.potential != params.row.bioMass.defaultPotential && (
                      <Tooltip title={"Biomethane Potential (Default)"}>
                        <span style={{ color: theme.palette.text.primary }}>
                          ({NumberFormatter(params.row.bioMass.defaultPotential, FRONTEND_NUMBER_FORMAT, 1)})
                        </span>
                      </Tooltip>
                    )}
                  </Typography>
                );
              },
            },
            {
              field: "pricePerTonnage",
              headerName: "Price per Tonnage",
              minWidth: 100,
              maxWidth: 150,
              flex: 1,
              type: "string",
              editable: false,
              renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
            },

            {
              field: "supplier",
              headerName: "Supplier",
              minWidth: 100,
              maxWidth: 150,
              flex: 1,
              type: "string",
              editable: false,
              valueGetter: (params) => params.row.supplier.owner,
            },
            {
              field: "actions",
              headerName: "",
              disableColumnMenu: true,
              sortable: false,
              flex: 1,
              align: "right",
              filterable: false,
              renderCell: (params) => {
                return (
                  <Box>
                    <Tooltip title="Add to production">
                      <Button variant="text" onClick={() => handleAddMode(params.row)} startIcon={<PostAdd />} color="primary" size="small">
                        Add to production
                      </Button>
                    </Tooltip>

                    <Tooltip title="Edit biomassproduct">
                      <IconButton aria-label="create" color="secondary" onClick={() => handleEditMode(params.row)}>
                        <ModeEdit fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Remove biomassproduct">
                      <IconButton aria-label="create" color="error" onClick={() => handleRemove(params.row)}>
                        <DeleteForever fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              },
            },
          ]}
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
          density="compact"
          isRowSelectable={() => false}
          hideFooterSelectedRowCount
          sx={defaultTableStyle}
        />
      </Box>
    </Box>
  );
};

export default BioMassPotentialTable;
