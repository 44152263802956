import { Container } from "@mui/material";
import BioMassCenter from "./components/BioMassCenter";

const MasterDataCenter = () => {

  return (
    <Container sx={{ mb: 5 }}>
        <BioMassCenter />
    </Container>
  );
};

export default MasterDataCenter;