import { Box, Container, Divider, Typography, useTheme } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import MonthlySummaryTable from "../../components/tables/MonthlySummaryTable";
import { useFetchMonthlySummaries } from "../../hooks/substrates";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";

const MonthlySummary = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const { data, isLoading, isError } = useFetchMonthlySummaries(user?.plantId ?? "");

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Monthly summary
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      {data && <MonthlySummaryTable data={data} />}
    </Container>
  );
};

export default MonthlySummary;
