import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

const InputFormText = ({
  name,
  control,
  label,
  required,
  errorText,
  disabledTextField,
  type,
  pattern,
  inputProps,
  customValidation,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
        pattern: pattern,
        validate: customValidation ? customValidation : undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? errorText : null}
          fullWidth
          size="small"
          error={!!error}
          onChange={onChange}
          value={value}
          label={label}
          variant="outlined"
          sx={{ m: 2 }}
          type={type}
          required={required}
          disabled={disabledTextField}
          InputProps={
            inputProps && {
              endAdornment: inputProps.endAdornment,
              startAdornment: inputProps.startAdornment,
            }
          }
        />
      )}
    />
  );
};

export default InputFormText;
