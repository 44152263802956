import { AxiosError } from "axios";
import { DateFormatter } from "../../../utils/DateFormatter";
import { Typography, Divider, Box, Button, useTheme, CircularProgress, Alert } from "@mui/material";
import { toast } from "react-toastify";
import { useAddMonthlyProduction } from "../../../hooks/plant";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import InputFormDatePicker from "../../../components/form-components/InputFormDatePicker";
import InputFormText from "../../../components/form-components/InputFormText";
import { BACKEND_NUMBER_FORMAT, NumberFormatter } from "../../../utils/NumberFormatter";

type CreateProductionDataForm = {
  certificates: number | undefined;
  other: number | undefined;
  torch: number | undefined;
  methaneLoss: number | undefined;
  producedAt: Date;
};

const defaultValues = {
  certificates: undefined,
  other: undefined,
  torch: undefined,
  methaneLoss: undefined,
};

type Props = {
  showCreate: (value: boolean) => void;
  plantId: string;
};

const CreateProductionData = ({ showCreate, plantId }: Props) => {
  const theme = useTheme();
  const location = useLocation();
  const addMonthlyProduction = useAddMonthlyProduction(plantId);
  const methods = useForm<CreateProductionDataForm>({
    defaultValues: {
      producedAt: location?.state?.selectedDate || new Date(),
      ...defaultValues,
    },
  });
  const { handleSubmit, control } = methods;

  const onSubmit = (values: CreateProductionDataForm) => {
    values.producedAt.setDate(1);

    if (!values.methaneLoss) {
      return;
    }

    const methaneLoss = NumberFormatter(values.methaneLoss, BACKEND_NUMBER_FORMAT) as number;

    const requestData = {
      certificates: Number(values.certificates),
      other: Number(values.other),
      torch: Number(values.torch),
      methaneLoss: methaneLoss / 100,
      producedAt: DateFormatter(values.producedAt),
    };

    addMonthlyProduction.mutate(
      { plantId, values: requestData },
      {
        onSuccess: () => {
          toast.success("Production data successfully created!");
          showCreate(false);
        },
        onError(error, variables, context) {
          console.info(error, variables, context);
        },
      },
    );
  };

  const displayError = () => {
    if (addMonthlyProduction.error instanceof AxiosError) {
      const message = addMonthlyProduction.error.response?.data?.detail;
      return <Typography variant="body2">{message}</Typography>;
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 2, mb: 2 }}>
        <Typography variant="body2">Create new monthly report Katt</Typography>
        <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
        <Box sx={{ px: 4, pt: 3, display: "flex", justifyContent: "center" }}>
          <InputFormDatePicker
            name="producedAt"
            control={control}
            label="Select month"
            format="MM/yyyy"
            formatViews={["month", "year"]}
            required={true}
            disabledTextField={location?.state?.selectedDate || false}
            unsetMargin
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ px: 2 }}>
            <InputFormText
              type="number"
              name="certificates"
              control={control}
              label="Cert - KWh"
              required={true}
              errorText={"Certificates required"}
            />
          </Box>
          <Box sx={{ px: 2 }}>
            <InputFormText
              type="number"
              name="other"
              control={control}
              label="Other - KWh"
              required={true}
              errorText={"Other is required"}
            />
          </Box>
          <Box sx={{ px: 2 }}>
            <InputFormText
              type="number"
              name="torch"
              control={control}
              label="Flare - KWh"
              required={true}
              errorText={"Flare is required"}
            />
          </Box>
          <Box sx={{ px: 2 }}>
            <InputFormText
              type="number"
              name="methaneLoss"
              control={control}
              label="Loss - %"
              required={true}
              errorText={"Methane loss is required"}
            />
          </Box>
        </Box>
        <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button variant="text" color="secondary" onClick={() => showCreate(false)}>
            Cancel
          </Button>
          {addMonthlyProduction.isPending ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <Button variant="contained" color="success" type="submit">
              Save
            </Button>
          )}
        </Box>
        {!!addMonthlyProduction.error && (
          <Box mt={2}>
            <Alert severity="error">{displayError()}</Alert>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default CreateProductionData;
