import { useParams } from "react-router-dom";
import { Grid, Box, Typography, Divider, useTheme } from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import { useFetchPlants } from "../../hooks/plant";
import { DateFormatter, FRONTEND_DATE_FORMAT } from "../../utils/DateFormatter";

const PlantDetails = () => {
  const theme = useTheme();
  const { id } = useParams() as { id: string };
  const { data, isLoading, isError } = useFetchPlants();

  const plant = data?.find((item: { id: string }) => item.id == id);

  if (isLoading) return <LoadingSpinner />;

  if (isError || !plant) return <ShowError />;


  return (
    <Box sx={{ p: 2, m: 2 }} key={plant.name}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h6" sx={{ flexGrow: 1, pl: 2 }}>
          {plant.name}
        </Typography>
      </Box>
      <Divider sx={{ m: 2, bgcolor: theme.palette.primary.main }} />
      <Grid container spacing={2} sx={{ display: "flex", flexDirection: "row", p: 2 }}>
        <Grid item xs={6}>
          <Typography variant="h6">Address</Typography>
          <Box>
            <Typography variant="body1">
              Country: <strong>{plant.country}</strong>
            </Typography>
            <Typography variant="body1">
              Street name: <strong>{plant.street}</strong>
            </Typography>
            <Typography variant="body1">
              Postal code: <strong>{plant.postalCode}</strong>
            </Typography>
            <Typography variant="body1">
              City: <strong>{plant.city}</strong>
            </Typography>
          </Box>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Contact
          </Typography>
          <Box>
            <Typography variant="body1">
              Contact person: <strong>{plant.contactPerson}</strong>
            </Typography>
            <Typography variant="body1">
              Phone number: <strong>{plant.phoneNumber}</strong>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Plant configuration</Typography>
          <Typography variant="body1">
            Closed digestate: <strong>{plant.closedDigestate ? "Yes" : "No"}</strong>
          </Typography>
          <Typography variant="body1">
            Off-gas Combustion: <strong>{plant.offGasCombustion ? "Yes" : "No"}</strong>
          </Typography>
          <Typography variant="body1">
            Commissioning date: <strong>{DateFormatter(plant.commissioningDate, FRONTEND_DATE_FORMAT)}</strong>
          </Typography>
          <Typography variant="body1">
            Certificate Number: <strong>{plant.certificateNumber}</strong>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PlantDetails;
