import { AcceptedCountry } from "../types";

export const getCountryISO = (country: string): AcceptedCountry => {
  switch (country.toLowerCase()) {
    case "sweden":
    case "se": {
      return "SE";
    }
    case "denmark":
    case "dk": {
      return "DK";
    }
    case "netherlands":
    case "dutch":
    case "holland":
    case "nl": {
      return "NL";
    }
    case "poland":
    case "pl": {
      return "PL";
    }
    case "germany":
    case "de": {
      return "DE";
    }
    case "belgium":
    case "be": {
      return "BE";
    }
    case "italy":
    case "it": {
      return "IT";
    }
    default:
      return "";
  }
};

export const availableCountries = [
  {
    name: "Sweden (SE)",
    id: "SE",
  },
  {
    name: "Denmark (DK)",
    id: "DK",
  },
  {
    name: "Poland (NL)",
    id: "NL",
  },
  {
    name: "Netherlands (PL)",
    id: "PL",
  },
  {
    name: "Germany (DE)",
    id: "DE",
  },
  {
    name: "Belgium (BE)",
    id: "BE",
  },
  {
    name: "Italy (IT)",
    id: "IT",
  },
];
