import { useState } from "react";
import { useForm } from "react-hook-form";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { useAddCustomer } from "../../../hooks/customer";
import { CustomerRequest } from "../../../types/index";
import CustomerForm from "./CustomerForm";

const defaultValues: CustomerRequest = {
  name: "",
  contactPerson: "",
  street: "",
  city: "",
  postalCode: "",
  phoneNumber: "",
  country: "",
  certificateNumber: "",
  activated: true,
  powerBiUrl: "",
  isSupplier: false,
  isRecipient: false,
};

type CreateCustomerProps = {
  showCreate: (value: boolean) => void;
};

const CreateCustomer = ({ showCreate }: CreateCustomerProps) => {
  const [loading, setLoading] = useState(false);
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, control } = methods;
  const { mutate: addCustomer } = useAddCustomer();

  const onSubmit = async (newCustomer: CustomerRequest) => {
    setLoading(true);
    addCustomer(newCustomer, {
      onSuccess: () => {
        showCreate(false);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 2, my: 2 }}>
        <Typography variant="h6">New customer</Typography>
        <CustomerForm control={control} />
        <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
          <Button variant="text" color="secondary" onClick={() => showCreate(false)}>
            Cancel
          </Button>
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Button variant="contained" color="success" type="submit">
                Save
              </Button>
            </>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default CreateCustomer;
