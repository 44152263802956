import axios from "axios";
import { scopes, msalInstance } from "./msal";
import { BASE_URL } from "../services/base";

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(
  async (config) => {
    const account = msalInstance.getAllAccounts()[0];

    if (account && config.baseURL == BASE_URL) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes: scopes,
        account: account,
      });

      if (accessTokenResponse) {
        const accessToken = accessTokenResponse.accessToken;

        if (config.headers && accessToken) {
          config.headers["Authorization"] = "Bearer " + accessToken;
        }
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle unauthorized error
    if (error.response && error.response.status === 401) {
      localStorage.setItem("user", "");
      localStorage.setItem("role", "");
      msalInstance.logoutRedirect();
    }
    return Promise.reject(error);
  },
);

export default instance;
