import { Box, Container, Divider, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import { useState } from "react";
import { AddCircle } from "@mui/icons-material";
import UserTable from "../../components/tables/UserTable";
import { useFetchUsers } from "../../hooks/users";
import CreateUser from "./components/CreateUser";

const Users = () => {
  const [showCreate, setShowCreate] = useState(false);
  const theme = useTheme();
  const { data, isLoading, isError } = useFetchUsers();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Users
        </Typography>
        {!showCreate && (
          <Tooltip title="Create new Customer">
            <IconButton aria-label="create" color="primary" onClick={() => setShowCreate(!showCreate)}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      {showCreate && <CreateUser showCreate={(value: boolean) => setShowCreate(value)} />}
      {data && (
        <Box sx={{ height: 750, maxWidth: "100%" }}>
          <UserTable data={data} />
        </Box>
      )}
    </Container>
  );
};

export default Users;
