import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from "@mui/material";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useParams } from "react-router";
import LoadingSpinner from "../../components/LoadingSpinner";
import MonthlyProductionTable from "./components/MonthlyProductionTable";
import {
  useCreateSubstrateMonth,
  useExportSubstrateMonth,
  useFetchMonthlySummary,
  useLockSubstrateMonth,
  useUnlockSubstrateMonth,
} from "../../hooks/substrates";
import { useAuth } from "../../hooks/useAuth";
import { DateFormatter, BACKEND_DATE_FORMAT } from "../../utils/DateFormatter";
import ConfirmModal from "../../components/ConfirmModal";
import { FRONTEND_NUMBER_FORMAT, NumberFormatter } from "../../utils/NumberFormatter";

const monthTotal = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;
const years = Array(currentYear + 1 - (currentYear - 5))
  .fill("")
  .map((_v, idx) => currentYear + 1 - idx);

interface DateSelectorProps {
  onDateSelected: (year: string, month: string) => void;
  year: string;
  month: string;
}

const DateSelector = ({ onDateSelected, year, month }: DateSelectorProps) => {
  const [selectedDates, setSelectedDates] = useState({
    year: year || currentYear.toString(),
    month: month || currentMonth.toString(),
  });

  const handleDateChange = (e: SelectChangeEvent) => {
    const newSelectedDates = { ...selectedDates, [e.target.name]: e.target.value };
    setSelectedDates(newSelectedDates);
    onDateSelected(newSelectedDates.year, newSelectedDates.month);
  };

  const previousMonth = () => {
    const prevMonth = Number(month) - 1 < 1 ? 12 : Number(month) - 1;
    const prevYear = Number(month) - 1 < 1 ? Number(year) - 1 : Number(year);
    const newSelectedDates = { year: prevYear.toString(), month: prevMonth.toString() };
    setSelectedDates(newSelectedDates);
    onDateSelected(newSelectedDates.year, newSelectedDates.month);
  };

  const nextMonth = () => {
    const nextMonth = Number(month) + 1 > 12 ? 1 : Number(month) + 1;
    const nextYear = Number(month) + 1 > 12 ? Number(year) + 1 : Number(year);
    const newSelectedDates = { year: nextYear.toString(), month: nextMonth.toString() };
    setSelectedDates(newSelectedDates);
    onDateSelected(newSelectedDates.year, newSelectedDates.month);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton aria-label="delete" onClick={() => previousMonth()} sx={{ mr: 2 }} size="small" color="success">
          <NavigateBefore />
        </IconButton>
      </Box>
      <Box sx={{ minWidth: 150 }}>
        <FormControl fullWidth>
          <InputLabel id="year-select">Year</InputLabel>
          <Select
            displayEmpty
            labelId="year-select-label"
            id="year-select"
            name="year"
            value={year}
            label="Year"
            onChange={handleDateChange}>
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ minWidth: 150, mx: 2 }}>
        <FormControl fullWidth>
          <InputLabel id="month-select">Month</InputLabel>
          <Select labelId="month-select-label" id="month-select" name="month" value={month} label="Month" onChange={handleDateChange}>
            {monthTotal.map((item: string, index) => (
              <MenuItem key={index} value={index + 1}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton aria-label="delete" onClick={() => nextMonth()} sx={{ mr: 2 }} color="success">
          <NavigateNext />
        </IconButton>
      </Box>
    </>
  );
};

const MonthlyProduction = () => {
  const { user } = useAuth();
  const { year = currentYear, month = currentMonth } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [confirmLockedMonthId, setConfirmLockedMonthId] = useState<string | null>();

  const date = DateFormatter(new Date(Number(year), parseInt(month as string) - 1, 1), BACKEND_DATE_FORMAT);
  const { data: monthData, isLoading: isMonthLoading } = useCreateSubstrateMonth(user?.plantId ?? "", date);
  const { mutate: lockSubstrateMonthMutation } = useLockSubstrateMonth(user?.plantId ?? "", date);
  const { mutate: exportSubstrateMonthMutation } = useExportSubstrateMonth();
  const { mutate: unlockSubstrateMonth } = useUnlockSubstrateMonth(user?.plantId ?? "");
  const { data: monthSummary } = useFetchMonthlySummary(monthData?.id ?? "");

  const handleDateSelectorChanged = (year: string, month: string) => {
    navigate(`/daily-production/${year}/${month}`);
  };

  const onConfirmClosed = (cancelled: boolean) => {
    if (!cancelled && confirmLockedMonthId) {
      lockSubstrateMonthMutation(confirmLockedMonthId);
    }
    setConfirmLockedMonthId(null);
  };

  const exportMonth = (monthId: string) => {
    exportSubstrateMonthMutation(monthId);
  };

  return (
    <Container>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
            Monthly overview
          </Typography>
          <DateSelector
            year={year.toString()}
            month={month.toString()}
            onDateSelected={(year: string, month: string) => handleDateSelectorChanged(year, month)}
          />
          {!!monthData && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {monthData?.isLocked ? (
                <Button onClick={() => unlockSubstrateMonth(monthData?.id)} variant="contained" color="inherit" sx={{ mr: 2 }} size="small">
                  Unlock Month
                </Button>
              ) : (
                <Button
                  disabled={isMonthLoading || monthData?.isLocked}
                  onClick={() => setConfirmLockedMonthId(monthData?.id)}
                  variant="contained"
                  color="inherit"
                  sx={{ mr: 2 }}
                  size="small">
                  Send substrate list
                </Button>
              )}

              <Button onClick={() => exportMonth(monthData?.id)} variant="contained" color="inherit" sx={{ mr: 2 }} size="small">
                Export all days
              </Button>
            </Box>
          )}
        </Box>
        <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ p: 1, width: 140, m: 1 }}>
            <Typography variant="caption">
              <strong>Expected production</strong>
            </Typography>
            <Typography> {NumberFormatter(monthSummary?.expectedProduction, FRONTEND_NUMBER_FORMAT, 0) ?? 0} m3</Typography>
          </Box>
          <Box sx={{ p: 1, width: 140, m: 1 }}>
            <Typography variant="caption">
              <strong>Measured production</strong>
            </Typography>
            <Typography> {NumberFormatter(monthSummary?.measuredProduction, FRONTEND_NUMBER_FORMAT, 0) ?? 0} m3</Typography>
          </Box>
          <Box sx={{ p: 1, width: 200, m: 1 }}>
            <Typography variant="caption">
              <strong>Total biomass cost </strong>
            </Typography>
            <Typography> {NumberFormatter(monthSummary?.totalGasPrice, FRONTEND_NUMBER_FORMAT, 0) ?? 0} EUR</Typography>
          </Box>
          <Box sx={{ p: 1, width: 140, m: 1 }}>
            <Typography variant="caption">
              <strong>Expected gas cost</strong>
            </Typography>
            <Typography> {NumberFormatter(monthSummary?.expectedAverageGasPrice, FRONTEND_NUMBER_FORMAT, 3) ?? 0} EUR/m3</Typography>
          </Box>
          <Box sx={{ p: 1, width: 100, m: 1 }}>
            <Typography variant="caption">
              <strong>Feedstocks</strong>
            </Typography>
            <Typography> {NumberFormatter(monthSummary?.substratesCount, FRONTEND_NUMBER_FORMAT, 0) ?? 0}</Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        {/* <Divider sx={{ m: 2, bgcolor: theme.palette.primary.main }} /> */}
        {isMonthLoading && <LoadingSpinner />}
        {!isMonthLoading && monthData && (
          <Box sx={{ maxWidth: "100%" }}>
            <MonthlyProductionTable monthData={monthData} />
          </Box>
        )}
      </Box>
      {!!confirmLockedMonthId && (
        <ConfirmModal
          open={true}
          title="Send substrate list"
          actionButtonSuccess="Send"
          actionButtonCancel="Cancel"
          onClose={onConfirmClosed.bind(this)}>
          <Box>
            <Box>This will lock the month and no additional data can be added. Make sure you have verified that all days is reported.</Box>
            <Box sx={{ marginTop: 2 }}>Are you sure you want to send substrate list to biolens?</Box>
          </Box>
        </ConfirmModal>
      )}
    </Container>
  );
};

export default MonthlyProduction;
