import { Container, Box, Typography, Divider, useTheme } from "@mui/material";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import IncomingPosTable from "../../components/tables/IncomingPosTable";
import { useFetchIncomingPosByPlant } from "../../hooks/incomingPos";
import { useAuth } from "../../hooks/useAuth";

const IncomingPosByPlant = () => {
  const { user } = useAuth();
  const { data, isLoading, isError } = useFetchIncomingPosByPlant(user?.plantId ?? "");
  const theme = useTheme();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Produced PoS
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ mt: 2 }}>{data ? <IncomingPosTable data={data} /> : "No data were found"}</Box>
    </Container>
  );
};

export default IncomingPosByPlant;
