import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { CustomerResponse, CustomerRequest } from "../../../types/index";
import CustomerForm from "./CustomerForm";
import { useUpdateCustomer } from "../../../hooks/customer";
import { getCountryISO } from "../../../utils/CountryToIso";

const defaultValues: CustomerRequest = {
  name: "",
  contactPerson: "",
  street: "",
  city: "",
  postalCode: "",
  phoneNumber: "",
  country: "",
  certificateNumber: "",
  activated: true,
  powerBiUrl: "",
  isSupplier: false,
  isRecipient: false,
};

type UpdateCustomerProps = {
  selectedCustomer: CustomerResponse;
  showEdit: (value: boolean) => void;
};

const UpdateCustomer = ({ selectedCustomer, showEdit }: UpdateCustomerProps) => {
  const [loading, setLoading] = useState(false);
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, control, reset } = methods;
  const { mutate: updateCustomer } = useUpdateCustomer();

  useEffect(() => {
    const defaultValues: CustomerRequest = {
      name: selectedCustomer.name,
      contactPerson: selectedCustomer.contactPerson,
      street: selectedCustomer.street,
      city: selectedCustomer.city,
      postalCode: selectedCustomer.postalCode,
      phoneNumber: selectedCustomer.phoneNumber,
      country: getCountryISO(selectedCustomer.country),
      certificateNumber: selectedCustomer.certificateNumber,
      activated: true,
      powerBiUrl: selectedCustomer.powerBiUrl,
      isSupplier: selectedCustomer.isSupplier,
      isRecipient: selectedCustomer.isRecipient,
    };
    reset(defaultValues);
  }, [selectedCustomer, reset]);

  const onSubmit = (values: CustomerRequest) => {
    setLoading(true);
    const requestData = {
      ...values,
      id: selectedCustomer.id,
    };

    updateCustomer(requestData, {
      onSuccess() {
        showEdit(false);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ p: 2, my: 2 }}>
        <Typography variant="h6">Update customer</Typography>
        <CustomerForm control={control} />
        <Box sx={{ display: "flex", justifyContent: "end", m: 2 }}>
          <Button variant="text" color="secondary" onClick={() => showEdit(false)}>
            Cancel
          </Button>
          {loading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Button variant="contained" color="success" type="submit">
                Save
              </Button>
            </>
          )}
        </Box>
      </Box>
    </form>
  );
};

export default UpdateCustomer;
