import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { Alert, Avatar, Box, Button, CircularProgress, Container, Paper, Typography, useTheme } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../services/msal";
import { InteractionStatus } from "@azure/msal-browser";
import { REDIRECT_URI, ADMIN_ROLE, PLANT_OWNER_ROLE } from "../services/base";
import Footer from "./Footer";

export default function SignIn() {
  const [loadingAdmin, setLoadingAdmin] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loadingPlantOwner, setLoadingPlantOwner] = useState(false);
  const { showError: authError, setAndGetUserFromBackend, loginRequestRole, setLoginRequestRole } = useAuth();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const theme = useTheme();

  useEffect(() => {
    if (inProgress === InteractionStatus.None && accounts.length > 0 && authError) {
      setLoadingAdmin(false);
      setLoadingPlantOwner(false);
    }
  }, [authError, accounts.length, inProgress]);

  useEffect(() => {
    if (isAuthenticated && instance.getAllAccounts().length > 0 && loginRequestRole) {
      instance.setActiveAccount(instance.getAllAccounts()[0]);
      setAndGetUserFromBackend(instance.getAllAccounts()[0].username, loginRequestRole);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (authError) {
      localStorage.setItem("loginError", "true");
      instance.logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authError]);

  useEffect(() => {
    if (localStorage.getItem("loginError") == "true") {
      setLoginError(true);
      localStorage.removeItem("loginError");
    }
  }, []);

  const handleAdminAzureLogin = async () => {
    setLoginRequestRole(ADMIN_ROLE);
    setLoadingAdmin(true);
    await instance.loginRedirect({ ...loginRequest, redirectUri: `${REDIRECT_URI}` }).catch((e) => {
      setLoadingAdmin(false);
      console.log(e);
    });
  };

  const handlePlanOwnerAzureLogin = async () => {
    setLoginRequestRole(PLANT_OWNER_ROLE);
    setLoadingPlantOwner(true);
    await instance.loginRedirect({ ...loginRequest, redirectUri: `${REDIRECT_URI}` }).catch((e) => {
      setLoadingPlantOwner(false);
      console.log(e);
    });
  };

  return (
    <Container component="main">
      <Box display="flex" flexDirection="column" alignItems="center" width="100%" mb={10}>
        <Avatar sx={{ mb: 1, bgcolor: theme.palette.primary.main }}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          BioLens
        </Typography>
        <Box mt={3} display="flex" gap={3} flexWrap="wrap" justifyContent="center">
          <Paper elevation={1} sx={{ p: 2, width: 400, height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
            {(isAuthenticated && loginRequestRole === ADMIN_ROLE) || loadingAdmin ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5" sx={{ p: 2, mb: 2 }} color={theme.palette.primary.main} align="center">
                  BioLens Admin
                </Typography>

                <Box>
                  <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                    <Button onClick={() => handleAdminAzureLogin()} variant="contained" color="primary">
                      Log in
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Paper>

          <Paper elevation={1} sx={{ p: 2, width: 400, height: 200, display: "flex", justifyContent: "center", alignItems: "center" }}>
            {loadingPlantOwner || (isAuthenticated && loginRequestRole === PLANT_OWNER_ROLE) ? (
              <Box sx={{ display: "flex" }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h5" sx={{ p: 2, mb: 2 }} color={theme.palette.primary.main} align="center">
                  Plant Operator
                </Typography>

                <Box>
                  <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                    <Button onClick={() => handlePlanOwnerAzureLogin()} variant="contained" color="primary">
                      Log in
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Paper>
        </Box>
        <Box mt={3}>{loginError && <Alert severity="error">{`You don't have right permission to see the content.`}</Alert>}</Box>
        <Footer />
      </Box>
    </Container>
  );
}
