import { Select, MenuItem, Typography, InputLabel, FormControl, IconButton, Tooltip, FormHelperText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller } from "react-hook-form";
import { FormInputProps } from "./FormInputProps";

export const InputFormSelect = ({
  name,
  control,
  label,
  required,
  data,
  unsetMargin,
  resetField,
  customValidation,
  errorText,
}: FormInputProps) => {
  const generateSelectOptions = () => {
    if (data) {
      return data.map((option: { id: string; name: string; category?: string }) => {
        return (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
            {option.category ? <Typography variant="caption">| Category: {option.category}</Typography> : ""}
          </MenuItem>
        );
      });
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: required, validate: customValidation ? customValidation : undefined }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth sx={{ m: unsetMargin ? "unset" : 2 }} required={required} size="small" error={!!error}>
          <InputLabel id={`select-label-${label}`}>{label}</InputLabel>
          <Select
            label={label}
            labelId={`select-label-${label}`}
            onChange={onChange}
            value={value}
            displayEmpty
            error={!!error}
            variant="outlined"
            IconComponent={
              resetField && value && value.length > 1
                ? () => (
                    <Tooltip title="Reset" onClick={() => resetField()}>
                      <IconButton size="small" sx={{ fontSize: "1rem", cursor: "pointer", position: "absolute", right: "7px" }}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )
                : undefined
            }>
            {generateSelectOptions()}
          </Select>
          {error && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
