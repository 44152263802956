import { Box, Divider, Typography, useTheme } from "@mui/material";
import { useFetchPlantBioMassProducts } from "../../hooks/biomassproduct";
import AdminBioMassPotentialTable from "./AdminBioMassPotentialTable";

type Props = {
  plantId: string;
};

const AdminListPlantPotential = ({ plantId }: Props) => {
  const theme = useTheme();
  const { data } = useFetchPlantBioMassProducts(plantId);

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Biomethane potentials
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <AdminBioMassPotentialTable data={data ?? []} />
    </>
  );
};

export default AdminListPlantPotential;
