import { useNavigate, useParams } from "react-router-dom";
import { Toolbar, Tabs, Tab, Box, Container, Divider } from "@mui/material";

const PlantMenu = ({ children }: { children: React.JSX.Element }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [[currentRootPath]] = location.pathname.matchAll(/\/([^\\/]+)\/?$/g);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    navigate(`/plants/${id}${newValue}`);
  };

  const TabDelimiter = () => {
    return (
      <Tab
        label=""
        icon={<Divider orientation="vertical" sx={{ height: 30, alignSelf: "center" }} />}
        sx={{ maxWidth: 5, minWidth: 5, p: 0, mx: 1 }}
        disabled
      />
    );
  };

  return (
    <Container>
      <Box>
        <Toolbar sx={{ display: "flex", justifyContent: "center", mb: 3 }} variant="dense">
          <Tabs value={currentRootPath} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Details" value="/details" />
            <TabDelimiter />
            <Tab label="Biomethane potentials" value="/potentials" />
            <TabDelimiter />
            <Tab label="Substrate list" value="/substrates" />
            <TabDelimiter />
            <Tab label="Production data" value="/productions" />
          </Tabs>
        </Toolbar>
        <Box sx={{ display: "flex", alignItems: "left", flexDirection: "column", justifyContent: "center" }}>{children}</Box>
      </Box>
    </Container>
  );
};

export default PlantMenu;
