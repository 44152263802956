import { AddCircle } from "@mui/icons-material";
import { Box, Typography, Container, Divider, IconButton, Tooltip, useTheme } from "@mui/material";
import { useFetchPlants } from "../../hooks/plant";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import PlantTable from "../../components/tables/PlantTable";

const Plant = () => {
  const { data, isLoading, isError } = useFetchPlants();
  const navigate = useNavigate();
  const theme = useTheme();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Plants
        </Typography>
        <Box>
          <Tooltip title="Create new plant">
            <IconButton aria-label="create" color="primary" onClick={() => navigate("/plants/new")}>
              <AddCircle />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      {data && <PlantTable data={data} />}
    </Container>
  );
};

export default Plant;
