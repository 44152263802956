import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    DialogContentText
  } from "@mui/material";
  

type Props = {
    title: string;
    actionButtonSuccess: string;
    actionButtonCancel: string; 
    open: boolean|true;
    onClose: (cancelled: boolean) => void;
    children: JSX.Element;
};

const ConfirmModal = (props: Props) => {
    return (
    <div>
        <Dialog
            open={props.open}
            onClose={() => props.onClose(true)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => props.onClose(true)}>
                {props.actionButtonCancel}
            </Button>
            <Button onClick={() => props.onClose(false)}>
                {props.actionButtonSuccess}
            </Button>
            </DialogActions>
        </Dialog>
    </div>
    )
};

export default ConfirmModal;