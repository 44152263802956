import * as msal from "@azure/msal-browser";
import { RedirectRequest } from "@azure/msal-browser";
import { AUTHORITY, CLIENT_ID, KNOWN_AUTHORITIES, REDIRECT_URI } from "./base";

const msalConfig = {
  auth: {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    clientId: CLIENT_ID!,
    authority: AUTHORITY,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: REDIRECT_URI,
    knownAuthorities: [`${KNOWN_AUTHORITIES}`],
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: msal.BrowserCacheLocation.SessionStorage, // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const scopes = [CLIENT_ID!];

const loginRequest: RedirectRequest = {
  scopes: [],
};

const msalInstance = new msal.PublicClientApplication(msalConfig);

export { msalInstance, loginRequest, scopes };
