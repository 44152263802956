import { useState } from "react";
import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ChevronRight, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import UpdatePlant from "../../pages/plant/components/Update";
import { defaultTableStyle } from "../themes/tableStyle";
import { PlantResponse } from "../../types";

type Props = {
  data: PlantResponse[];
};

const PlantTable = ({ data }: Props) => {
  const [showEdit, setShowEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState<PlantResponse>();
  const navigate = useNavigate();

  const handleUpdatePlant = (row: PlantResponse) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setShowEdit(true);
    setSelectedRow(row);
  };

  return (
    <Box sx={{ height: 750, maxWidth: "100%" }}>
      {showEdit && selectedRow && (
        <Box sx={{ p: 2, m: 2 }}>
          <UpdatePlant data={selectedRow} showEdit={(value: boolean) => setShowEdit(value)} />{" "}
        </Box>
      )}
      <DataGrid
        rows={data}
        slots={{
          toolbar: GridToolbar,
        }}
        columns={[
          {
            field: "name",
            headerName: "Name",
            minWidth: 120,
            maxWidth: 140,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "country",
            headerName: "Country",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "city",
            headerName: "City",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "certificateNumber",
            headerName: "Certificate number",
            minWidth: 150,
            maxWidth: 250,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "commissioningDate",
            headerName: "Commissioning date",
            minWidth: 100,
            maxWidth: 150,
            flex: 1,
            type: "string",
            editable: false,
            renderCell: (params) => <Typography variant="caption">{params.value}</Typography>,
          },
          {
            field: "actions",
            headerName: "",
            disableColumnMenu: true,
            sortable: false,
            flex: 0.5,
            align: "right",
            filterable: false,
            renderCell: (params) => {
              return (
                <Box>
                  <Tooltip title="Update plant">
                    <IconButton color="info" onClick={() => handleUpdatePlant(params.row)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Open">
                    <IconButton aria-label="create" color="primary" onClick={() => navigate(`/plants/${params.row.id}/details`)}>
                      <ChevronRight />
                    </IconButton>
                  </Tooltip>
                </Box>
              );
            },
          },
        ]}
        initialState={{ pagination: { paginationModel: { page: 0, pageSize: 25 } } }}
        density="compact"
        isRowSelectable={() => false}
        hideFooterSelectedRowCount
        sx={defaultTableStyle}
      />
    </Box>
  );
};

export default PlantTable;
