  // eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createFilter = (filterText: string) => (value: any) => {
  if (filterText && filterText.length) {
    const filterTextLowerCase = filterText.toLowerCase();
    for (const key in value) {
      const hasMatch = ("" + value[key]).toLowerCase().indexOf(filterTextLowerCase.toLowerCase());
      if (hasMatch > -1) {
        return true;
      }
    }
    return false;
  } else {
    return true;
  }
};