import { Box, Container, Divider, Typography, useTheme } from "@mui/material";
import MassbalanceTable from "../../components/tables/MassBalanceTable";
import LoadingSpinner from "../../components/LoadingSpinner";
import ShowError from "../../components/ShowError";
import { useFetchMassbalancesByPlant } from "../../hooks/massbalance";
import { useAuth } from "../../hooks/useAuth";

const MassbalanceByPlant = () => {
  const { user } = useAuth();
  const { data, isLoading, isError } = useFetchMassbalancesByPlant(user?.plantId ?? "");
  const theme = useTheme();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ShowError />;
  }

  return (
    <Container>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h4" sx={{ flexGrow: 1, color: theme.palette.primary.main }}>
          Massbalances
        </Typography>
      </Box>
      <Divider sx={{ my: 2, bgcolor: theme.palette.primary.main }} />
      <Box sx={{ mt: 2 }}>{data ? <MassbalanceTable data={data} /> : "No massbalances were found"}</Box>
    </Container>
  );
};

export default MassbalanceByPlant;
