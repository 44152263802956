import { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import InputFormText from "../../../components/form-components/InputFormText";
import InputFormDatePicker from "../../../components/form-components/InputFormDatePicker";
import { InputFormRadio } from "../../../components/form-components/InputFormRadio";
import { useUpdatePlant } from "../../../hooks/plant";
import { DateFormatter } from "../../../utils/DateFormatter";
import { toast } from "react-toastify";
import { availableCountries, getCountryISO } from "../../../utils/CountryToIso";
import { InputFormSelect } from "../../../components/form-components/InputFormSelect";
import { PlantRequest, PlantResponse } from "../../../types";

const defaultValues: PlantRequest = {
  name: "",
  country: "",
  certificateNumber: "",
  commissioningDate: "",
  street: "",
  postalCode: "",
  city: "",
  contactPerson: "",
  phoneNumber: "",
  closedDigestate: false,
  offGasCombustion: false,
};

type Props = {
  data: PlantResponse;
  showEdit: (value: boolean) => void;
};

const UpdatePlant = ({ data, showEdit }: Props) => {
  const methods = useForm({ defaultValues: defaultValues });
  const { handleSubmit, control, reset } = methods;
  const updatePlant = useUpdatePlant();

  useEffect(() => {
    const defaultValues = {
      name: data.name,
      country: getCountryISO(data.country) as string,
      certificateNumber: data.certificateNumber,
      commissioningDate: data.commissioningDate,
      street: data.street,
      postalCode: data.postalCode,
      city: data.city,
      contactPerson: data.contactPerson,
      phoneNumber: data.phoneNumber,
      closedDigestate: data.closedDigestate,
      offGasCombustion: data.offGasCombustion,
    };
    reset(defaultValues);
  }, [data, reset]);

  const onSubmit = (values: PlantRequest) => {
    const requestData = {
      ...values,
      commissioningDate: DateFormatter(values.commissioningDate),
    };

    updatePlant.mutate(
      { plantId: data.id, plantData: requestData },
      {
        onSuccess: () => {
          toast.success("Plant updated!");
          showEdit(false);
        },
      },
    );
  };

  const checkboxData = [
    {
      label: "No",
      value: false,
    },
    {
      label: "Yes",
      value: true,
    },
  ];

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ maxWidth: 500 }}>
          <Box sx={{ display: "flex" }}>
            <InputFormText name="name" control={control} label="Plant name" required errorText={"Plant name is required"} />
            <InputFormSelect name="country" control={control} label="Country" data={availableCountries} />
          </Box>

          <Box sx={{ display: "flex" }}>
            <InputFormText name="street" control={control} label="Street name" />
            <InputFormText name="postalCode" control={control} label="Postal code" />
          </Box>
          <Box sx={{ width: "94%" }}>
            <InputFormText name="city" control={control} label="City" />
          </Box>
          <Box sx={{ display: "flex" }}>
            <InputFormText name="contactPerson" control={control} label="Contact person" />
            <InputFormText name="phoneNumber" control={control} label="Phone number" />
          </Box>
          <Box sx={{ width: "94%" }}>
            <InputFormText name="certificateNumber" control={control} label="Certificate number" />
          </Box>
          <Box sx={{ width: "94%" }}>
            <InputFormDatePicker
              name="commissioningDate"
              control={control}
              label="Commissioning date"
              required={false}
              format="dd/MM/yyyy"
              formatViews={["day", "month", "year"]}
              fullWidth
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
            <InputFormRadio name="offGasCombustion" control={control} label="Off Gas Combustion" data={checkboxData} />
            <InputFormRadio name="closedDigestate" control={control} label="Closed digestate" data={checkboxData} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
          <Button variant="text" color="secondary" onClick={() => showEdit(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="success" type="submit">
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UpdatePlant;
